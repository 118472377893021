<script>
  import CRUDList from './CRUDList_Tastypie.svelte'
  import { __ } from './utils.js'

  const baseURL = '/api/v1/addressbook/'

  $: title = __('Address book')
  $: titleCreate = __('Create')
  $: titleEdit = __('Edit')

  const fields = [
    { name: 'first_name', caption: __('First name') },
    { name: 'last_name', caption: __('Last name') },
    { name: 'street', caption: __('Street') },
    {
      name: 'street_number',
      caption: __('Street/Number'),

      props: { type: 'number', step: 1, min: 0 },
    },
    { name: 'post_code', caption: __('Post Code') },
    { name: 'city', caption: __('City') },
    { name: 'telephone', caption: __('Telephone'), props: { type: 'tel' } },
    {
      name: 'telephone_mobile',
      caption: __('Telephone Mobile'),
      props: { type: 'tel' },
    },
    { name: 'email', caption: __('EMail'), props: { type: 'email' } },
  ]
</script>

<CRUDList
  baseURL="{baseURL}"
  fields="{fields}"
  sortField="first_name"
  title="{title}"
  titleCreate="{titleCreate}"
  titleEdit="{titleEdit}" />
