<script>
  /** @typedef { import("./types").CalendarEvent } CalendarEvent */
  import { differenceInCalendarDays, isSameDay } from 'date-fns'
  import { createEventDispatcher } from 'svelte'
  import { __ } from '../utils.js'

  export let targetedDate = new Date()
  /** @type {CalendarEvent[]} */
  export let events = []

  const dispatch = createEventDispatcher()

  $: _date = normalizeTargetedDate(targetedDate)
  function normalizeTargetedDate(d) {
    const dd = new Date(d)
    dd.setHours(0, 0, 0, 0)
    return dd
  }

  $: items = [...events]
    .filter((e) => {
      if (!e.end) return isSameDay(e.start, _date)

      return (
        differenceInCalendarDays(e.start, _date) <= 0 &&
        differenceInCalendarDays(e.end, _date) >= 0
      )
    })
    .sort((a, b) => a.start.getTime() - b.start.getTime())
</script>

<style>
  .dayview {
    user-select: none;
  }

  .dayview .events {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .dayview .event-slot {
    /* border: none; */
    margin: 0.25em 0.5em;
    padding: 0.5em;
    font: inherit;
    color: inherit;
    text-align: start;
    vertical-align: text-top;
  }
</style>

<div
  class="dayview relative flex flex-col h-full w-full overflow-hidden text-sm">
  <ul class="events">
    {#each items as event, ie (event.id)}
      <button
        class="event-slot section items-center"
        class:allday="{event.allday}"
        id="{event.id}"
        on:click="{() => {
          dispatch('clickEvent', { event })
        }}">
        <div class="flex-1">{event.title}</div>
        <div class="text-xs">
          {event.start.toLocaleDateString()}
          {#if !event.allday}
            {event.start.toLocaleTimeString()}
          {/if}
          {#if event.end}
            ▸
            {event.end.toLocaleDateString()}
            {#if !event.allday}
              {event.end.toLocaleTimeString()}
            {/if}
          {/if}
        </div>
      </button>
    {:else}
      <div class="p-4 italic w-full text-center">
        {__('No event on this day')}
      </div>
    {/each}
  </ul>
</div>
