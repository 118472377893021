<script>
  import { onMount } from 'svelte'
  import { has_permission, check_feature, omnibus_subscribe, ajax, __ } from '../utils.js'
  import ButtonIcon from './ButtonIcon.svelte'
  import { mdiAccountPlus } from '@mdi/js'

  let value = null

  const reload_status = () => {
    ajax.get('/ajax/imported_inmates').then((e) => {
      value = e.data?.result
    })
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe('prisec', 'importedinmates_counter', reload_status)
  })
</script>

{#if check_feature('inmates') && value}
  <ButtonIcon
    value="{value}"
    warning="{value > 10}"
    color="#86A134"
    icon="{mdiAccountPlus}"
    tooltip="{__('Import inmates transfered from other locations')}"
    label="{__('Transfered Inmatees')}"
    url="/Inmates/Imported" />
{/if}
