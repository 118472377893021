<script>
  import { onMount } from 'svelte'
  import CRUDList from './CRUDList_Tastypie.svelte'
  import { QWebChannel } from './qwebchannel.js'
  import Checkbox from './uielements/Checkbox.svelte'
  import { __ } from './utils.js'

  const baseURL = '/api/v1/alarm/'

  $: title = __('List of alarms')
  $: titleCreate = __('Create')
  $: titleEdit = __('Edit')

  $: fields = [
    // { name: 'name', caption: __('Title') },
    {
      name: 'time_trigger',
      caption: __('Time'),
      props: { type: 'time', min: '07:00', max: '20:00' },
    },
    { name: 'days_trigger', caption: __('Days'), props: { type: 'day' } },
    {
      name: 'once',
      caption: __('1x : It will automatically deactivate.'),
      props: { type: 'checkbox' },
    },
    { name: 'enabled', caption: __('Active'), props: { type: 'checkbox' } },
  ]

  const DAYS = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]

  $: dayCaptions = DAYS.map(__)

  const QWCOperations = { save: null, remove: null }

  onMount(() => {
    if (!window.qt?.webChannelTransport) return

    new QWebChannel(qt.webChannelTransport, function (channel) {
      const errorHandler = (err) => console.error(err)

      QWCOperations.save = function (obj) {
        // console.log('calling QWebChannel.objects.handler.save(obj)', JSON.stringify(obj, 0, 2))
        channel.objects.handler.save(obj, errorHandler)
      }
      QWCOperations.remove = function (obj) {
        // console.log('calling QWebChannel.objects.handler.remove(obj)', JSON.stringify(obj, 0, 2))
        channel.objects.handler.remove(obj, errorHandler)
      }
    })
  })

  const getDefaultAlarm = function () {
    return {
      payload: {
        time_trigger: `12:00`,
        enabled: false,
        once: false,
        days_trigger: [true, true, true, true, true, false, false],
      },
    }
  }

  function transformIncomingItem(alarm) {
    try {
      alarm.payload = {
        ...alarm.payload,
        // transform the incoming time string "00:00:00" to the format expected
        // by a time input: "00:00"
        time_trigger: alarm.payload.time_trigger?.substring(0, 5),
        once: !!alarm.payload.once,
        // DB: days_trigger: "mon,tue,wed,thu,fri",
        // UI: days_trigger: [true, true, true, true, true, false, false],
        days_trigger: DAYS.map((D) =>
          alarm.payload.days_trigger.includes(D.substring(0, 3))
        ),
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      return alarm
    }
  }

  function transformOutgoingItem(alarm) {
    // DB: days_trigger: "mon,tue,wed,thu,fri",
    // UI: days_trigger: [true, true, true, true, true, false, false],
    alarm.payload.days_trigger = alarm.payload.days_trigger
      .reduce((agg, d, i) => {
        if (d) agg.push(DAYS[i].substring(0, 3))
        return agg
      }, [])
      .join(',')

    let once = alarm.payload.once
    if (once) {
      let [hours, minutes] = alarm.payload.time_trigger.split(':')
      // Calculate the next iteration of this "once" occurence.
      // Giving a Date to the backend instead of naive hours and minutes
      // also offers the opportunity to pass the timezone to the backend.
      const nextIteration = new Date()
      nextIteration.setHours(hours)
      nextIteration.setMinutes(minutes)
      alarm.payload.once = nextIteration.toISOString()
    } else {
      delete alarm.payload.once
    }

    return alarm
  }
</script>

<div class="relative h-full overflow-hidden text-3xl">
  <CRUDList
    baseURL="{baseURL}"
    fields="{fields}"
    sortField="id"
    title="{title}"
    titleCreate="{titleCreate}"
    titleEdit="{titleEdit}"
    transformIncomingItem="{transformIncomingItem}"
    transformOutgoingItem="{transformOutgoingItem}"
    getDefaultItem="{getDefaultAlarm}"
    maxItems="3"
    CAN_CREATE="{true}"
    CAN_DELETE="{true}"
    CAN_EDIT="{true}"
    on:save="{(e) => QWCOperations.save?.(e.detail)}"
    on:delete="{(e) => QWCOperations.remove?.(e.detail)}"
    actionBlueSpace="{(item, onSave) => {
      item.payload.enabled = !item.payload.enabled
      onSave(item)
    }}">
    <div
      slot="list-item"
      let:item
      let:onSave
      class="flex p-1 items-center text-gray-700"
      class:text-gray-900="{item.payload.enabled}"
      class:font-bold="{item.payload.enabled}">
      <div class="" style="flex-grow:1;">
        <Checkbox
          tabindex="-1"
          value="{item.payload.enabled}"
          checked="{item.payload.enabled}"
          on:change="{({ target }) => {
            item.payload.enabled = !!target.checked
            onSave(item)
          }}" />
        <span class="m-1 text-sm" class:opacity-0="{!item.payload.once}"
          >1x</span>
      </div>
      <div class="text-center" style="flex-grow:3;">
        {item.payload.time_trigger ?? ''}
      </div>
      <div
        class="self-stretch m-1 p-1 flex justify-evenly items-center rounded border-none bg-white"
        style="flex-grow:4;">
        {#each dayCaptions as day, i}
          <div
            style="max-width: 2em; max-height: 2em;"
            class="h-8 m-px flex-1 font-bold text-sm rounded-3xl flex justify-center items-center"
            class:bg-primary-900="{item.payload?.days_trigger?.[i]}">
            {day[0].toUpperCase()}
          </div>
        {/each}
      </div>
    </div>
  </CRUDList>
</div>
