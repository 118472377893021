<script>
  import { datefns_locale } from './utils'
  import {
    startOfMonth,
    getDay,
    subDays,
    endOfMonth,
    addDays,
    differenceInWeeks,
    addWeeks,
    addMonths,
    subMonths,
  } from 'date-fns'
  import { mdiArrowLeftThick, mdiArrowRightThick } from '@mdi/js'
  import Icon from 'mdi-svelte'
  import {createEventDispatcher} from 'svelte'

  export let date = new Date()

  const dispatch = createEventDispatcher()
  const _loc = datefns_locale()
  const locale = {
    weekStartsOn: _loc.options.weekStartsOn,
    weekdays: [...Array(7).keys()].map((i) =>
      _loc.localize.day(i, { width: 'short' })
    ),
    months: [...Array(12).keys()].map((i) =>
      _loc.localize.month(i, { width: 'wide' })
    ),
  }

  $: _startOfMonth = startOfMonth(date)
  $: _startDate = subDays(
    _startOfMonth,
    (getDay(_startOfMonth) - locale.weekStartsOn + 7) % 7
  )
  $: _endOfMonth = endOfMonth(date)
  $: _endDate = addDays(
    _endOfMonth,
    (14 - getDay(_endOfMonth) + locale.weekStartsOn) % 7
  )
  $: _weeks = differenceInWeeks(_endDate, _startDate)
  $: weeks = [...Array(_weeks).keys()].map((ww) => addWeeks(_startDate, ww))

  const days = (start) => [...Array(7).keys()].map((day) => addDays(start, day))
</script>

<div class="flex flex-col select-none">
  <div class="w-full flex flex-row justify-between pb-4 text-primary-500">
    <div class="cursor-pointer" on:click="{() => (date = subMonths(date, 1))}">
      <Icon path="{mdiArrowLeftThick}" size="2rem" />
    </div>
    <div class="text-3xl">
      {locale.months[date.getMonth()]}
      {date.getFullYear()}
    </div>
    <div class="cursor-pointer" on:click="{() => (date = addMonths(date, 1))}">
      <Icon path="{mdiArrowRightThick}" size="2rem" />
    </div>
  </div>
  <table>
    <thead>
      <tr class="font-bold">
        {#each [...Array(7).keys()] as day}
          <th class="font-bold px-2">
            {locale.weekdays[(day + locale.weekStartsOn) % 7]}
          </th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each weeks as week}
        <tr>
          {#each days(week) as day}
            <td
              class="py-2 px-3 text-center cursor-pointer"
              class:text-gray-500="{day.getMonth() != date.getMonth()}"
              class:rounded-full="{day.getDate() == date.getDate() &&
                day.getMonth() == date.getMonth()}"
              class:bg-primary-500="{day.getDate() == date.getDate() &&
                day.getMonth() == date.getMonth()}"
              class:text-white="{day.getDate() == date.getDate() &&
                day.getMonth() == date.getMonth()}"
              on:click="{() => dispatch("selected", day)}">
              {day.getDate()}
            </td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
</div>
