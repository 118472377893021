<script>
  import { datefns_locale } from './utils'
  import { format, parse } from 'date-fns'
  import Popover from 'svelte-easy-popover'
  import DatePicker from './DatePicker.svelte'

  export let name = 'date'
  export const required = false
  export let value

  const _loc = datefns_locale()

  const _now = new Date()
  let date = value ? parse(value, 'yyyy-MM-dd', _now) : _now

  const closeOnClickAway = (elem) => {
    const listener = (evt) => {
      isOpen = refElem?.contains(evt.target) || elem.contains(evt.target)
    }
    document.addEventListener('click', listener)
    return { destroy: () => document.removeEventListener('click', listener) }
  }

  const on_selected = (evt) => {
    date = evt.detail
    isOpen = false
  }

  let refElem
  let isOpen = false
</script>

<input type="hidden" name="{name}" value="{format(date, 'yyyy-MM-dd')}" />
<input
  bind:this="{refElem}"
  class="form-control"
  value="{format(date, 'P', { locale: _loc })}"
  on:focus="{() => (isOpen = true)}" />
<Popover
  isOpen="{isOpen}"
  referenceElement="{refElem}"
  placement="bottom"
  spaceAway="{10}"
  spaceAlong="{40}">
  <div use:closeOnClickAway class="z-20 bg-white p-2 border border-solid">
    <DatePicker date="{date}" on:selected="{on_selected}" />
  </div>
</Popover>
