<script>
  import { onMount } from 'svelte'
  import { has_permission, check_feature, omnibus_subscribe, ajax, __ } from '../utils.js'
  import ButtonIcon from './ButtonIcon.svelte'
  import { mdiPrinter } from '@mdi/js'

  let value = null

  const reload_status = () => {
    ajax.get('/ajax/pending_print_jobs').then((e) => {
      value = e.data?.result
    })
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe('prisec', 'waitingprintjobs_counter', reload_status)
  })
</script>

{#if check_feature('prisonmedia_office') && has_permission('inmate_media_office_edit') && value}
  <ButtonIcon
    value="{value}"
    warning="{value > 10}"
    color="#F88017"
    icon="{mdiPrinter}"
    tooltip="{__('Waiting print jobs')}"
    label="{__('Print Jobs')}"
    url="/office/Inmates/PrintApproval" />
{/if}
