<script>
  import { onMount } from 'svelte'
  import { has_permission, check_feature, omnibus_subscribe, ajax, __ } from '../utils.js'
  import ButtonIcon from './ButtonIcon.svelte'
  import { mdiAccount } from '@mdi/js'

  let value = null

  const reload_status = () => {
    ajax.get('/ajax/contact_requests').then((e) => {
      value = e.data?.result
    })
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe('prisec', 'vvwhitelist_counter', reload_status)
  })
</script>

{#if check_feature('videovisit') && has_permission('inmate_phone_blackwhitelist_edit') && value}
  <ButtonIcon
    value="{value}"
    warning="{value > 10}"
    color="#77B2AE"
    icon="{mdiAccount}"
    tooltip="{__('VideoVisit contact requests')}"
    label="{__('Contacts')}"
    url="/VideoVisit/Whitelist?panelX=panel_0&status=0" />
{/if}
