<script>
  import { itemActions, highlightSearch, CloseButton } from 'svelecte/item'

  export let index = -1
  export let item = {}
  export let isSelected = false
  export let isMultiple = false

  const legend = {
    Inmate: 'Ⓘ',
    InmateGroup: 'Ⓖ',
    Ward: 'Ⓦ',
    Location: 'Ⓛ',
  }

  const getColor = (ii) => {
    return 'text-red-500'
  }
</script>

<style>
  /** some style override */
  :global(.sv-dd-item-active) .sv-item-content {
    color: red !important;
  }
</style>

<!-- you need to use itemActions and pass given events -->
<div
  class="sv-item"
  use:itemActions="{{ item, index }}"
  class:is-selected="{isSelected}"
  on:select
  on:deselect
  on:hover>
  <div class="sv-item-content">{legend[item.type]} {item.label}</div>
  {#if isSelected && isMultiple}
    <CloseButton />
  {/if}
</div>
