<script>
  /** @typedef { import("./types").CalendarEvent } CalendarEvent */

  import {
    addDays,
    addHours,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    endOfWeek,
    getHours,
    isSameDay,
    isWeekend,
    startOfWeek,
  } from 'date-fns'
  import { createEventDispatcher } from 'svelte'
  import { MONTHS, WEEK_DAYS } from './CalendarBase.svelte'

  export let targetedDate = new Date()
  /** @type {CalendarEvent[]} */
  export let events = []

  let alldayCells = []

  let timedCells = []

  /** @type {CalendarEvent} */
  let hoveredEvent

  const dispatch = createEventDispatcher()

  const FIRST_WEEK_DAY = 1
  $: weekDays = WEEK_DAYS.map((__, i) => WEEK_DAYS[(i + FIRST_WEEK_DAY) % 7])

  $: firstDayShown = startOfWeek(targetedDate, {
    weekStartsOn: FIRST_WEEK_DAY,
  })
  $: lastDayShown = endOfWeek(targetedDate, {
    weekStartsOn: FIRST_WEEK_DAY,
  })

  $: moveToDate(targetedDate)

  const moveToDate = (target) => {
    const firstDay = startOfWeek(target, {
      weekStartsOn: FIRST_WEEK_DAY,
    })

    // init list of cells
    alldayCells = weekDays.map((weekDayName, i) => {
      const _fulldate = addDays(firstDay, i)
      return {
        _fulldate,
        _title: weekDayName.substr(0, 3),
      }
    })

    // init list of cells
    timedCells = Array.from(Array(24 * 7), (_, i) => {
      return {
        _fulldate: addHours(firstDay, i),
        _time: `${('0' + (i % 24)).substr(-2)}:00`,
      }
    })
  }

  $: _events = events
    .filter((event) => {
      return (
        differenceInDays(event.start, lastDayShown) <= 0 ||
        differenceInDays(event.end || event.start, firstDayShown) >= 0
      )
    })
    .sort((a, b) => a.start.getTime() - b.start.getTime())

  $: applyAllDayEvents(
    _events.filter((e) => e.allday),
    alldayCells
  )
  /** @param {CalendarEvent[]} _events */
  const applyAllDayEvents = (_events) => {
    // rude
    alldayCells.forEach((c) => {
      // init at a safe value, we'll shorten them at the end
      c.slots = Array(_events.length)
    })
    let maxSlotStack = 1

    _events.forEach((event, __i) => {
      // console[event.id == 8 ? 'group' : 'groupCollapsed'](event.id, event.title)

      const start = new Date(event.start)
      const end = event.end ? new Date(event.end) : start
      // if (event.allday) end = startOfDay(addDays(end, 1))

      const iFirstCellOfEvent = Math.max(
        0,
        differenceInDays(event.start, firstDayShown)
      )
      const iLastCellOfEvent = Math.min(6, differenceInDays(end, firstDayShown))

      // console.log({ start: event.start, end })
      // console.log({ firstDayShown, lastDayShown })
      // console.log({ iFirstCellOfEvent, iLastCellOfEvent })
      // console.log({ diff: differenceInDays(end, firstDayShown) })

      // Find the highest available section by going over all cells covered by this event
      let level = 0
      for (let iCell = iFirstCellOfEvent; iCell <= iLastCellOfEvent; iCell++) {
        level = Math.max(
          level,
          alldayCells[iCell].slots.findIndex((o) => !o)
        )
      }
      maxSlotStack = Math.max(maxSlotStack, level + 1)

      // Register the cell slots this event is taking
      for (let iCell = iFirstCellOfEvent; iCell <= iLastCellOfEvent; iCell++) {
        const iEvent = iCell - differenceInDays(event.start, firstDayShown)
        const isSection = iEvent == 0 || iCell % 7 == 0

        const length = (isSection ? iLastCellOfEvent - iCell : 1) + 1

        if (iEvent == 0 || iCell == 0)
          alldayCells[iCell].slots[level] = {
            event,
            length,
            isSection: true,
            isFirst: iEvent == 0,
            isLast: (isSection && !event.end) || event.end <= lastDayShown,
          }
        else alldayCells[iCell].slots[level] = { event }
      }

      // console.groupEnd()
    })

    alldayCells.forEach(({ slots }) => (slots.length = maxSlotStack + 1))
  }

  $: applyTimedEvents(
    _events.filter((e) => !e.allday),
    timedCells
  )
  /** @param {CalendarEvent[]} _events */
  const applyTimedEvents = (_events) => {
    // rude
    timedCells.forEach((c) => {
      // init at a safe value, we'll shorten them at the end
      c.slots = Array(_events.length || 1)
    })
    let maxSlotStack = 1

    _events.forEach((event, __i) => {
      // console[event.id == 12 ? 'group' : 'groupCollapsed'](
      //   event.id,
      //   event.title
      // )
      // console.log(event.start)
      // console.log(event.end)

      if (!event.end)
        return console.error('need an end for non-allday event', event)

      const end = new Date(event.end)

      const iFirstCellOfEvent = Math.max(
        0,
        differenceInHours(event.start, firstDayShown)
      )
      const iLastCellOfEvent = Math.min(
        7 * 24 - 1,
        differenceInHours(end, firstDayShown)
      )

      // Find the highest available section by going over all cells covered by this event
      let level = 0
      for (let iCell = iFirstCellOfEvent; iCell <= iLastCellOfEvent; iCell++) {
        level = Math.max(
          level,
          timedCells[iCell].slots.findIndex((o) => !o)
        )
      }
      maxSlotStack = Math.max(maxSlotStack, level + 1)

      // Register the cell slots this event is taking
      for (let iCell = iFirstCellOfEvent; iCell <= iLastCellOfEvent; iCell++) {
        const iEvent = iCell - differenceInHours(event.start, firstDayShown)
        const isSection = iEvent == 0 || iCell % 24 == 0

        const length = isSection
          ? Math.min(
              differenceInMinutes(
                event.end || event.start,
                timedCells[iCell]._fulldate
              ) / 60,
              24 - (iCell % 24)
            )
          : 1

        if (iEvent == 0 || iCell % 24 == 0) {
          timedCells[iCell].slots[level] = {
            event,
            length,
            isSection: true,
            isFirst: iEvent == 0,
            isLast:
              (isSection && !event.end) || iCell % 24 < getHours(event.end),
            isLast:
              (isSection && !event.end) ||
              (isSameDay(timedCells[iCell]._fulldate, event.end) &&
                iCell % 24 <= getHours(event.end)),
          }
        } else {
          timedCells[iCell].slots[level] = { event }
        }

        const e = timedCells[iCell].slots[level]
        // console[e.isSection ? 'group' : 'groupCollapsed'](iCell)
        // console.log({ day: iCell % 24, level })
        // console.log('isFirst', e.isFirst)
        // console.log('isLast', e.isLast)
        // console.log('length', e.length)
        // console['groupEnd']()
      }

      // console.groupEnd()
    })

    timedCells.forEach(({ slots }) => (slots.length = maxSlotStack + 1))
  }
</script>

<style type="text/postcss">
  .weekview {
    user-select: none;
  }

  .events {
    overflow: visible;
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
  }

  .event-slot.section {
    z-index: 1;
  }

  .focused {
    @apply bg-primary-900;
    color: inherit;
  }

  /* --------
   * all day
   * -------- */

  .allday-container {
    font-size: small;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .allday-container .cellDay {
    overflow: visible;
    display: flex;
    flex-direction: column;
    padding: 0.3em 0;
  }
  .allday-container .cellDay {
    outline: 0.1em solid #eee6;
    /* box-shadow: inset 0 0 0 0.1em #eee6; */
  }
  .allday-container .cellDay.weekend {
    background-color: #3331;
  }

  .allday-container .event-slot {
    overflow: hidden;
    flex: 0 1 1.75em;
    margin: 0.1em 0;
    padding: 0 0.5em;
  }

  .allday-container .event-slot:empty {
    background: none;
  }
  .allday-container .event-slot.section.first {
    margin-left: var(--event-margin-x);
  }
  .allday-container .event-slot:not(.first) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .allday-container .event-slot:not(.last) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  /* --------
   * timed
   * -------- */

  .timed {
    display: grid;
    grid-template-rows: repeat(24, calc(100% / 24));
    grid-template-columns: repeat(7, calc(100% / 7));
    grid-auto-flow: column;
  }
  .timed .events {
    flex: 0 1 0;
    position: relative;
    flex-flow: row nowrap;

    outline: 0.1em solid #eee6;
  }
  .timed .events.weekend {
    background-color: #3331;
  }

  .timed .events > .time {
    /* we want it to not disrupt the events, but still be a bit visible */
    overflow-x: visible;
    width: 1em;
    /**/
    font-size: small;
    line-height: normal;
    margin: 0 0.5em;
    color: var(--time-color);
    border-bottom: none;
  }

  .timed .event-slot.section {
    position: relative;
    min-height: 100%;
    overflow: hidden;
    padding: 0 0.2em;
    margin: 0 0 0 0.1em;
  }
  .timed .event-slot:not(.first) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .timed .event-slot:not(.last) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
</style>

<div
  class="weekview relative flex flex-col h-full w-full overflow-hidden text-sm">
  <div class="headers flex">
    {#each alldayCells as cell, iCell}
      <span
        class="day-header flex-1"
        class:focused="{isSameDay(
          addDays(
            startOfWeek(targetedDate, {
              weekStartsOn: FIRST_WEEK_DAY,
            }),
            iCell
          ),
          targetedDate
        )}"
        on:click="{(e) => {
          moveToDate(cell._fulldate)
          dispatch('clickDay', { date: cell._fulldate })
        }}"
        on:dblclick|stopPropagation="{(e) => {
          dispatch('dblclickDay', { date: cell._fulldate })
        }}">{cell._title}</span>
    {/each}
  </div>

  <div class="allday-container">
    {#each alldayCells as cell, iDay (cell._fulldate)}
      <div
        class="cellDay"
        class:focused="{isSameDay(cell._fulldate, targetedDate)}"
        class:weekend="{iDay > 4}"
        on:click="{(e) => {
          moveToDate(cell._fulldate)
          dispatch('clickDay', { date: cell._fulldate })
        }}"
        on:dblclick|stopPropagation="{(e) => {
          dispatch('dblclickDay', { date: cell._fulldate })
        }}">
        <span class="text-center">
          {cell._fulldate.getDate()}
          {cell._fulldate.getDate() === 1
            ? MONTHS[cell._fulldate.getMonth() % 12].substr(0, 3)
            : ''}
        </span>

        <div class="events">
          {#each cell.slots as slot, iSlot (cell._fulldate.getTime() + iSlot)}
            {#if slot?.isSection}
              <button
                class="event-slot allday section"
                class:section="{slot.isSection}"
                class:first="{slot.isFirst}"
                class:last="{slot.isLast}"
                style="{`width: calc(${slot.length * 100}% - (
                    ${slot.isLast ? 1 : 0} + ${slot.isFirst ? 1 : 0}
                  ) * var(--event-margin-x));`}"
                on:click|stopPropagation="{() => {
                  dispatch('clickEvent', { event: slot.event })
                }}">
                <!-- {JSON.stringify(slot)} -->
                <!-- {slot.event.id}# {slot.event.title} -->
                {slot.event.title}
                <!-- len: {slot.length} -->
              </button>
            {:else}
              <div class="event-slot"></div>
            {/if}
          {/each}
        </div>
      </div>
    {/each}
  </div>

  <div
    class="timed flex-1 overflow-hidden border-0 border-solid border-t border-gray-500">
    {#each timedCells as cell, i}
      <ol
        class="events flex flex-col"
        class:focused="{isSameDay(cell._fulldate, targetedDate)}"
        class:weekend="{isWeekend(cell._fulldate, {
          weekStartsOn: FIRST_WEEK_DAY,
        })}"
        on:click="{(e) => {
          moveToDate(cell._fulldate)
          dispatch('clickDay', { date: cell._fulldate })
        }}"
        on:dblclick|stopPropagation="{(e) => {
          dispatch('dblclickDay', {
            date: cell._fulldate,
          })
        }}">
        {#if i < 24}
          <div class="time">{cell._time}</div>
        {/if}

        {#each cell.slots as slot, level}
          {#if slot?.isSection}
            <button
              class="event-slot"
              class:section="{slot.isSection}"
              class:first="{slot.isFirst}"
              class:last="{slot.isLast}"
              class:hover="{hoveredEvent === slot.event}"
              style="{`height: calc(${slot.length * 100}%);`}"
              on:click|stopPropagation="{() => {
                dispatch('clickEvent', { event: slot.event })
              }}"
              on:mouseenter="{() => {
                hoveredEvent = slot.event
              }}"
              on:mouseleave="{() => {
                hoveredEvent = null
              }}">
              <!-- {JSON.stringify(slot)} -->
              <!-- {slot.event.id}# {slot.event.title} -->
              {slot.event.title}
              <!-- len: {slot.length} -->
            </button>
          {/if}
        {/each}

        <!-- <div
            class="absolute top-0 left-0"
            style="color:#f00e; font-size:.5em; z-index:999;">
            {i}
          </div> -->
      </ol>
    {/each}
  </div>
</div>
