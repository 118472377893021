<script>
  import Svelecte from 'svelecte'
  import BlogReceiver from './BlogReceiver.svelte'
  import { __ } from './utils.js'

  export let url
  export let value
  export let name
  export let options

  const minQueryValue = 2

  const i18n = {
    empty: __('No options'),
    nomatch: __('No matching options'),
    max: (num) => __('Maximum items %(num)s selected', { num }),
    fetchBefore: __('Type to search'),
    fetchQuery: (minQuery) =>
      minQuery > 1
        ? __('Type at least %(minQuery)s character to search', { minQuery })
        : __('Type to search'),
    fetchEmpty: __('No data related to your search'),
    collapsedSelection: (count) => __('%(count)s selected', { count }),
    // createRowLabel: (value) => `Create '${value}'`,
  }

  const fetchCallback = (rsp) => {
    rsp = rsp.map((aa) => ({
      value: aa.pk,
      label: formatItem(aa),
      type: aa.typename,
    }))
    return rsp
  }

  const formatItem = (aa) => {
    if (aa.typename == 'Inmate')
      return aa.lastName
        ? aa.firstName
          ? `${aa.lastName}, ${aa.firstName} (${aa.inmateId})`
          : `${aa.lastName} (${aa.inmateId})`
        : aa.inmateId

    return aa.name
  }
</script>

<Svelecte
  name="{name}"
  controlItem="{BlogReceiver}"
  dropdownItem="{BlogReceiver}"
  options="{options.map((aa) => ({
    value: aa.pk,
    label: formatItem(aa),
    type: aa.typename,
  }))}"
  bind:value
  fetch="{url}?search=[query]"
  fetchCallback="{fetchCallback}"
  minQuery="{minQueryValue}"
  i18n="{i18n}"
  placeholder="{__('Select Receivers')}"
  multiple />
