<script>
  import { onMount } from 'svelte'
  import {
    has_permission,
    check_feature,
    omnibus_subscribe,
    ajax,
    __,
  } from '../utils.js'
  import ButtonIcon from './ButtonIcon.svelte'
  import { mdiCellphoneBasic } from '@mdi/js'

  let data

  $: mode = data?.mode
  $: label = mode ? __('New MobileWall Events') : __('Recent MobileWall Events')
  $: tooltip = mode
    ? __('View unconfirmed MobileWall events')
    : __('View recent MobileWall events')
  $: value = mode ? data?.unconfirmed : data?.recent

  const reload_status = () => {
    ajax.get('/ajax/mobilewall_events').then((e) => {
      data = e.data?.result
      // FIXME: mode && alarm -> audio alarm
    })
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe('prisec', 'mw_eventblocks_counter', reload_status)
  })
</script>

{#if check_feature('mobilewall') && !check_feature('detectorgroups') && value}
  <ButtonIcon
    value="{value}"
    color="#E89C19"
    icon="{mdiCellphoneBasic}"
    tooltip="{tooltip}"
    label="{label}"
    url="/MobileWall/MobileWallEvents/ListAll" />
{/if}
