<script>
  import { mdiCheckBold } from '@mdi/js'
  import Icon from 'mdi-svelte'
  import { createEventDispatcher, onMount, tick } from 'svelte'
  import Spinner from './uielements/Spinner.svelte'
  import Checkbox from './uielements/Checkbox.svelte'
  import { format_date, ajax } from './utils.js'

  const dispatch = createEventDispatcher()

  export let title = ''

  /**
   * @type {{ name: String, caption: String, props: {type: String} }[]}
   */
  export let fields

  export let sortField
  $: _sortField = sortField || fields[0].name

  export let transformIncomingItem = (o) => o
  // export let transformOutgoingItem = (o) => o

  // --------

  let isPending

  let items = []

  const setItems = async (input) => {
    items = sortItems(input)
    if (!lastFocusedItem && items?.length) lastFocusedItem = items[0]
  }

  function sortItems(items) {
    return items?.sort((a, b) => {
      const A = a?.[_sortField]?.toLowerCase()
      const B = b?.[_sortField]?.toLowerCase()
      return A > B ? 1 : A == B ? 0 : -1
    })
  }

  // --------

  onMount(() => {
    isPending = true

    ajax
      .get('/LeisureActivity/api/listInmateActivities')
      .then((e) => {
        value = e.data?.result
        setItems(e.data.map(transformIncomingItem))
      })
      .finally(() => {
        isPending = false
      })
  })

  const onSave = async (item) => {
    if (!item) return console.error({ item, lastFocusedItem, items })

    isPending = true

    ajax
      .get(
        '/LeisureActivity/api/setBooked/' + item.id + '&booked=' + item.booked
      )
      .then((e) => {
        const { booked } = e.data

        setItems(items?.map((o) => (o.id === item.id ? { ...o, booked } : o)))

        dispatch('save', item)
      })
      .finally(() => {
        isPending = false
      })
  }

  // --------

  let lastFocusedItem

  const focusItem = (item) => {
    if (lastFocusedItem === item) return

    lastFocusedItem = item
    const el = document.getElementById(`cruditem-${item.id}`)

    el?.focus()
  }

  const focusNextItem = function () {
    if (!items?.length) return

    const itemN = lastFocusedItem
      ? items.findIndex(({ id }) => id === lastFocusedItem.id)
      : 0

    focusItem(items[(itemN + 1) % items?.length])
  }

  const focusPreviousItem = function () {
    if (!items?.length) return

    const itemN = lastFocusedItem
      ? items.findIndex(({ id }) => id === lastFocusedItem.id)
      : items.length

    focusItem(items[(itemN > 0 ? itemN : items.length) - 1])
  }

  // --------

  const onClickItem = (item) => {
    focusItem(item)
    if (item.disabled) return
    item.booked = !item.booked
    onSave(item)
  }

  const onKeydown = (e) => {
    switch (e.key) {
      case 'Enter':
      case ' ':
      case '3':
      case 'ColorF2Yellow':
        return onClickItem(lastFocusedItem)
      case '5':
      case 'ChannelDown':
      case 'PageDown':
      case 'ArrowDown':
      case 'ArrowRight':
        return focusNextItem()
      case '6':
      case 'ChannelUp':
      case 'PageUp':
      case 'ArrowUp':
      case 'ArrowLeft':
        return focusPreviousItem()
      default:
        break
    }
  }
</script>

<svelte:window on:keydown="{onKeydown}" />

<!-- svelte-ignore a11y-autofocus -->
<div
  class="relative h-full overflow-hidden flex flex-col mx-10 rounded-xl bg-gray-400 border-solid border-4 border-gray-100">
  <div
    class="border-0 border-solid border-b border-gray-500 w-full flex flex-wrap items-center">
    <div class="flex-1 m-1 mx-2 text-lg font-medium">
      {#if title}{title}{:else}&nbsp;{/if}
    </div>
    <div
      class="flex-1 m-1 flex justify-end transition-opacity duration-500 opacity-0"
      class:opacity-100="{isPending}">
      <div>
        <Spinner />
      </div>
    </div>
  </div>

  <ul
    id="item-list"
    class="flex-1 overflow-auto flex flex-col m-0 p-0 bg-white">
    {#if items}
      {#each items as crt, j (crt.id)}
        <li
          id="cruditem-{crt.id}"
          class="flex items-center m-px p-1 border-0 border-b border-solid border-gray-200"
          class:bg-primary-900="{lastFocusedItem.id == crt.id}"
          class:text-gray-600="{crt.disabled}"
          class:bg-gray-400="{crt.disabled}"
          tabindex="{0}"
          on:click="{(e) => {
            onClickItem(crt)
          }}"
          on:focus="{() => focusItem(crt)}">
          <div class="p-2 w-16 text-4xl flex">
            <Checkbox
              disabled="{crt.disabled}"
              tabindex="-1"
              value="{crt?.booked}"
              checked="{crt?.booked}" />
          </div>
          <div class="flex-1">
            <div class="flex flex-wrap justify-between items-baseline">
              <div class="flex-grow font-extrabold">{crt.title}</div>
              <div
                class="flex flex-grow flex-wrap justify-end"
                style="min-width:10em">
                <span class="">{format_date(crt.start)}</span>
                {#if crt.end}
                  <div class="text-right">
                    <span class="px-1">-</span>{format_date(crt.end)}
                  </div>
                {/if}
              </div>
            </div>
            {@html crt.entryText ?? ''}
          </div>
        </li>
      {/each}
    {/if}
  </ul>
</div>

<div
  class="controls absolute bottom-0 text-base flex m-1 rounded bg-black text-gray-900 select-none shadow-md"
  style="left:50%; transform:translateX(-50%);">
  <button
    class="border-none bg-transparent m-2 rounded h-8 w-10"
    style="background-color: yellow"
    on:click="{() => onClickItem(lastFocusedItem)}">
    <Icon path="{mdiCheckBold}" size="1.5em" />
  </button>
</div>
