<script>
  import { onMount } from 'svelte'
  import { has_permission, check_feature, omnibus_subscribe, ajax, __ } from '../utils.js'
  import ButtonIcon from './ButtonIcon.svelte'
  import { mdiWeb } from '@mdi/js'

  let value = null

  const reload_status = () => {
    ajax.get('/ajax/pending_urls').then((e) => {
      value = e.data?.result
    })
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe('prisec', 'url_counter', reload_status)
  })
</script>

{#if check_feature('prisonmedia_www') && has_permission('location_media_urls_edit') && value}
  <ButtonIcon
    value="{value}"
    warning="{value > 10}"
    color="#437BAE"
    icon="{mdiWeb}"
    tooltip="{__('Internet URLs waiting for approval')}"
    label="{__('Waiting URLs')}"
    url="/Location/URLs?panelX=panel_0&status=0" />
{/if}
