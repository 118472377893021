<script context="module">
  /** @typedef { import("./calendar/types").CalendarEvent } CalendarEvent */

  import { __ } from '../utils.js'
  import DayView from './DayView.svelte'
  import MonthView from './MonthView.svelte'
  import WeekView from './WeekView.svelte'

  export const CALENDAR_VIEWS = {
    Month: { name: __('Month'), cmp: MonthView },
    Week: { name: __('Week'), cmp: WeekView },
    Day: { name: __('Day'), cmp: DayView },
  }

  export const WEEK_DAYS = [
    __('sunday'),
    __('monday'),
    __('tuesday'),
    __('wednesday'),
    __('thursday'),
    __('friday'),
    __('saturday'),
  ]

  export const MONTHS = [
    __('January'),
    __('February'),
    __('March'),
    __('April'),
    __('May'),
    __('June'),
    __('July'),
    __('August'),
    __('September'),
    __('October'),
    __('November'),
    __('December'),
  ]

  /** @typedef { { title: string, start: Date, end: Date } } CalendarEvent */
</script>

<script>
  export let targetedDate = new Date()

  export let activeView = CALENDAR_VIEWS.Month
</script>

<style type="text/postcss">
  :global(.calendarbase) {
    --event-radius: 0.4em;
    --event-color: #ddf6;
  }
  :global(.calendarbase) {
    --event-color: #ddf;
    --event-margin-x: 0.25em;
  }

  :global(.calendarbase .day-header) {
    text-transform: capitalize;
    text-align: center;
    padding-bottom: 1px;
  }

  :global(.calendarbase .event-slot) {
    margin: 0.1em 0;
    border: thin solid transparent;
    line-height: initial;
    font-size: initial;
  }

  :global(.calendarbase .event-slot.section) {
    display: flex;
    cursor: pointer;
    text-align: start;
    background-color: var(--event-color);
    /* @apply border border-primary-900; */
    border-radius: var(--event-radius);
  }
  :global(.calendarbase .allday.event-slot.section) {
  }

  :global(.calendarbase .event-slot.section:hover),
  :global(.calendarbase .event-slot.section.hover) {
    @apply border-primary-900;
  }

  :global(.calendarbase .event-slot.section:focus) {
    @apply bg-green-300;
  }
</style>

<div class="calendarbase flex flex-col h-full w-full overflow-hidden text-sm">
  {#each Object.keys(CALENDAR_VIEWS) as viewName}
    <div
      class="h-full w-full"
      class:hidden="{activeView !== CALENDAR_VIEWS[viewName]}">
      <svelte:component
        this="{CALENDAR_VIEWS[viewName].cmp}"
        bind:this="{CALENDAR_VIEWS[viewName].ref}"
        bind:targetedDate
        {...$$restProps}
        on:clickDay
        on:dblclickDay
        on:clickEvent />
    </div>
  {/each}
</div>
