<script>
  import ButtonBase from './ButtonBase.svelte'
  import Icon from 'mdi-svelte'

  let { icon, ...rest } = $$props
</script>

<ButtonBase {...rest}>
  <Icon path="{icon}" size="2em" />
</ButtonBase>
