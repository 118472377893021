<script>
  import LeisureActivityList from './LeisureActivity_List.svelte'
  import { __ } from './utils.js'

  $: title = __('Activities')

  $: fields = [
    { name: 'title', caption: __('Title') },
    { name: 'text', caption: __('Text') },
    { name: 'start', caption: __('Start') },
    { name: 'end', caption: __('End') },
    { name: 'availability', caption: __('availability') },
  ]

  const transformIncomingItem = (node) =>
    node
      ? {
          ...node,
          start: node.start && new Date(node.start),
          end: node.end && new Date(node.end),
          inmates: node.inmates?.edges?.map(({ node }) => node),
          disabled: !node.booked && !node.available,
        }
      : node
</script>

<div class="relative h-full overflow-hidden text-base">
  <LeisureActivityList
    transformIncomingItem="{transformIncomingItem}"
    fields="{fields}"
    sortField="id"
    title="{title}" />
</div>
