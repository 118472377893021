<script>
  import { onMount } from 'svelte'
  import ButtonBase from './ButtonBase.svelte'
  import Dialog from '../uielements/Dialog.svelte'
  import Button from '../uielements/Button.svelte'
  import {
    omnibus_subscribe,
    has_permission,
    location,
    ajax,
    __,
    check_features,
  } from '../utils.js'

  let modal = null
  let shutdown_active = false
  let count = null

  const on_click = (e) => {
    modal = shutdown_active ? msg_active : msg_inactive
  }

  const toggle_shutdown = () => {
    modal = false
    ajax.post('/ajax/emergency_shutdown')
  }

  const reload_status = () => {
    ajax.get('/ajax/emergency_shutdown').then((e) => {
      const data = e?.data?.result
      count = data?.length ? __('ACTIVE') : null
      shutdown_active = data?.includes('shortcut_btn')
      // FIXME: warning class falls andere Methoden aktiv sind
      // FIXME: active class
    })
  }

  // FIXME: arguments
  const msg_active = {
    title: __('End Emergency Shutdown'),
    body: __(
      'Are you sure you want end the Emergency Shutdown for %(location)s?',
      {location}
    ),
    button: __('Confirm end of Emergency Shutdown'),
  }

  const msg_inactive = {
    title: __('Emergency Shutdown'),
    body: __('Are you sure you want to shutdown %(location)s?', {location}),
    button: __('Confirm Emergency Shutdown'),
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe(
      'prisec',
      'emergency_status_changed',
      reload_status
    )
  })
</script>

{#if check_features( ['prisonphone', 'prisonmedia'] ) && has_permission('monitoring_emergencyshutdown_edit')}
  <ButtonBase
    color="#E53549"
    label="{__('Emergency Shutdown')}"
    warning="{shutdown_active}"
    value="{count}"
    on:click="{on_click}">
    STOP
  </ButtonBase>

  <Dialog bind:value="{modal}">
    <div slot="title">{modal.title}</div>
    {modal.body}
    <div slot="actions" class="flex items-end">
      <Button on:click="{() => (modal = false)}">{__('Cancel')}</Button>
      <Button dflt on:click="{toggle_shutdown}">{modal.button}</Button>
    </div>
  </Dialog>
{/if}
