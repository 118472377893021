<script>
  import { scale, fade } from 'svelte/transition'
  import { quadIn, quadOut } from 'svelte/easing'

  export let value
</script>

{#if value}
  <div class="fixed w-full h-full top-0 left-0 z-30">
    <div
      class="bg-black opacity-50 fixed top-0 left-0 z-10 w-full h-full"
      in:fade="{{ duration: 200, easing: quadIn }}"
      out:fade="{{ duration: 200, easing: quadOut }}"
      on:click="{() => (value = false)}">
    </div>

    <div class="h-full w-full absolute flex items-center justify-center">
      <div
        in:scale="{{ duration: 150, easing: quadIn, delay: 150 }}"
        class="w-3/5 items-center z-50 bg-white shadow-xl rounded-md">
        <div
          class="text-3xl px-6 py-6 border-solid border-0 border-gray-400 border-b">
          <slot name="title" />
        </div>
        <div class="text-2xl px-6 py-8 border-solid border-0 border-gray-400 border-b">
          <slot />
        </div>
        <div class="flex w-full justify-end px-6 py-6">
          <slot name="actions" />
        </div>
      </div>
    </div>
  </div>
{/if}
