<script>
  import { onMount } from 'svelte'
  import { check_feature, omnibus_subscribe, ajax, __ } from '../utils.js'
  import { throttle } from 'lodash-es'
  import ButtonIcon from './ButtonIcon.svelte'
  import { mdiPhone } from '@mdi/js'

  let value = null

  const reload_status = () => {
    ajax.get('/ajax/calls_waiting').then((e) => {
      value = e.data?.result
    })
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe('prisec', 'callsession_update', throttle(reload_status, 5000))
  })
</script>

{#if check_feature('prisonphone') && value}
  <ButtonIcon
    value="{value}"
    warning="{value > 10}"
    color="#C3C732"
    icon="{mdiPhone}"
    tooltip="{__('Calls waiting for approval')}"
    label="{__('Waiting Calls')}"
    url="/PrisonPhone/WaitingCalls" />
{/if}
