<script>
  // https://matejkustec.github.io/SpinThatShit/
  export let count = 3
  $: items = Array.from(Array(count)).map((_, i) => i)
</script>

<style>
  .animate-accordion {
    animation: accordion 1s infinite;
  }

  @keyframes accordion {
    0%,
    100% {
      transform: scaleY(100%);
      animationtimingfunction: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: scaleY(50%);
      animationtimingfunction: cubic-bezier(0, 0, 0.2, 1);
    }
  }
</style>

<div class="w-4 h-4 m-auto flex items-stretch justify-center">
  {#each items as item, i}
    <div
      class="item animate-accordion flex-1 mr-px bg-primary-700"
      style="animation-delay: {i * 0.2}s">
    </div>
  {/each}
</div>
