<script>
  export let dflt = false
</script>

<button
  type="button"
  class="btn mx-2"
  class:btn-default="{dflt}"
  on:click
  {...$$restProps}>
  <slot />
</button>
