<script>
  import { setHours, setMinutes } from 'date-fns'
  /** @typedef { import("./types").CalendarEvent } CalendarEvent */
  import { createEventDispatcher } from 'svelte'
  import Checkbox from '../uielements/Checkbox.svelte'
  import { __ } from '../utils.js'

  /** @type {CalendarEvent} */
  export let event

  $: __Title = event.id ? __('Event edition') : __('Event creation')
  $: __FormTitle = __('Title')
  $: __FormAllday = __('Whole day')
  $: __FormStart = __('Start')
  $: __FormEnd = __('End')
  $: __BtnDelete = __('Delete')
  $: __BtnSave = __('Save')
  $: __BtnClose = __('Close')

  const dispatch = createEventDispatcher()

  function formatDateForInput(d) {
    return d
      ? `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${(
          '0' + d.getDate()
        ).slice(-2)}`
      : null
  }

  let startDate = formatDateForInput(event.start)
  let endDate = formatDateForInput(event.end || event.start)

  let isAllDay = event.allday

  function formatTimeForInput(d) {
    return d
      ? `${('0' + d.getHours()).slice(-2)}:${('0' + d.getMinutes()).slice(-2)}`
      : null
  }

  let startTime = formatTimeForInput(event.start)
  let endTime = event.end ? formatTimeForInput(event.end) : null

  const getOutputEvent = () => {
    let start = new Date(startDate)
    let end = endDate && new Date(endDate)

    if (startTime) {
      start = setHours(start, startTime.slice(0, 2))
      start = setMinutes(start, startTime.slice(-2))
    }

    if (endDate && endTime) {
      end = setHours(end, endTime.slice(0, 2))
      end = setMinutes(end, endTime.slice(-2))
    }

    return {
      ...event,
      allday: isAllDay,
      title: event.title,
      start,
      end,
    }
  }
</script>

<style>
  .controls {
    max-width: 32em;
  }
</style>

<dialog
  open="{event}"
  class="eventdialog absolute top-0 z-10 w-full h-full rounded-lg bg-white">
  <div
    class="p-4 text-2xl font-medium bg-gray-2 border-0 border-solid border-b border-gray-500">
    <span>{__Title}</span>
  </div>
  <form class="m-6 mx-auto max-w-lg flex flex-col">
    <table>
      <tr>
        <td><label for="ftitle" class="text-right p-2">{__FormTitle}:</label></td>
        <td>
          <div class="w-full">
            <!-- svelte-ignore a11y-autofocus -->
            <input
              id="ftitle"
              class="w-full"
              bind:value="{event.title}"
              autofocus />
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <label for="isAllday" class="text-right p-2">{__FormAllday}:</label>
        </td>
        <td>
          <Checkbox id="isAllday" bind:checked="{isAllDay}" />
        </td>
      </tr>

      <tr>
        <td>
          <label for="fstart" class="text-right p-2">{__FormStart}:</label>
        </td>
        <td>
          <div class="flex">
            <input
              id="fstart"
              type="date"
              class="flex-1"
              bind:value="{startDate}" />
            <input
              id="fstarttime"
              type="time"
              bind:value="{startTime}"
              disabled="{isAllDay}" />
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <label for="fend" class="text-right p-2">{__FormEnd}:</label>
        </td>
        <td>
          <div class="flex">
            <input
              id="fend"
              type="date"
              class="flex-1"
              bind:value="{endDate}" />
            <input
              id="fendtime"
              type="time"
              bind:value="{endTime}"
              disabled="{isAllDay}" />
          </div>
        </td>
      </tr>
    </table>
  </form>
  <div class="controls mt-16 mx-auto w-full flex justify-evenly">
    <button
      on:click="{(e) => dispatch('delete', getOutputEvent())}"
      disabled="{!event.id}">
      {__BtnDelete}
    </button>
    <button on:click="{(e) => dispatch('save', getOutputEvent())}">
      {__BtnSave}
    </button>
    <button on:click="{(e) => dispatch('close', getOutputEvent())}">
      {__BtnClose}
    </button>
  </div>
</dialog>
