<script>
  import { onMount } from 'svelte'
  import { has_permission, check_feature, omnibus_subscribe, ajax, __ } from '../utils.js'
  import ButtonIcon from './ButtonIcon.svelte'
  import { mdiEmail } from '@mdi/js'

  let value = null

  const reload_status = () => {
    ajax.get('/ajax/pending_emails').then((e) => {
      value = e.data?.result ? e.data.result.inbound + e.data.result.outbound : 0
    })
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe('prisec', 'waitingemails_counter', reload_status)
  })
</script>

{#if check_feature('prisonmedia_email') && has_permission('inmate_media_email_edit') && value}
  <ButtonIcon
    value="{value}"
    warning="{value > 10}"
    color="#77B2AE"
    icon="{mdiEmail}"
    tooltip="{__('Emails waiting for approval')}"
    label="{__('Waiting Emails')}"
    url="/Inmates/EmailApproval" />
{/if}
