<script>
  import { onMount } from 'svelte'
  import { has_permission, check_feature, omnibus_subscribe, ajax, __ } from '../utils.js'
  import ButtonIcon from './ButtonIcon.svelte'
  import { mdiWaves } from '@mdi/js'

  let value = null

  const reload_status = () => {
    ajax.get('/ajax/device_events').then((e) => {
      value = e.data?.result
    })
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe('prisec', 'deviceevents_counter', reload_status)
  })
</script>

{#if has_permission('location_deviceevents_view') && value}
  <ButtonIcon
    value="{value}"
    color="#AB97A2"
    icon="{mdiWaves}"
    tooltip="{__('View and confirm device events')}"
    label="{__('Device Events')}"
    url="/Location/DeviceEvents" />
{/if}
