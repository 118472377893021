<script>
  import { onMount } from 'svelte'
  import { check_feature, omnibus_subscribe, ajax, __ } from '../utils.js'
  import ButtonIcon from './ButtonIcon.svelte'
  import { mdiBell } from '@mdi/js'

  let value = null
  let escalated = false

  const reload_status = () => {
    ajax.get('/ajax/call_panel_events').then((e) => {
      value = e.data?.result?.waiting
      escalated = e.data?.result?.escalated != 0
    })
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe('prisec', 'callpanelevents_counter', reload_status)
  })
</script>

{#if check_feature('prisoncall') && value}
  <ButtonIcon
    value="{value}"
    pulse="{escalated}"
    color="#F88017"
    icon="{mdiBell}"
    tooltip="{__('PrisonCall Alarms')}"
    label="{__('PrisonCalls')}"
    url="/PrisonCall/WaitingCallPanelEvents" />
{/if}
