import axios from 'axios'
import { intersection, isEmpty } from 'lodash-es'

// PC Identifier
let storage_key = 'CC_ClientBrowserSettings_ID'
export let browser_id = localStorage.getItem(storage_key)

if (browser_id === null) {
  // Copied from login.html
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  browser_id = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  localStorage.setItem(storage_key, browser_id)
}

// Axios API
export const ajax = axios.create({
  withCredentials: true,
  timeout: 10000,
  headers: { 'X-Requested-With': 'XMLHttpRequest', 'X-Browser-Identifier': browser_id },
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
})

// Context
export const has_permission = (perm) =>
  _svelteContext?.superuser || _svelteContext.permissions?.includes(perm)
export const is_superuser = () => _svelteContext?.superuser
// ...

export const check_feature = (feature) => {
  // return true
  if (!feature) return true
  return _svelteContext.features.includes(feature)
}

export const check_features = (items) => {
  // return true
  if (isEmpty(items)) return true
  return intersection(_svelteContext.features, items).length != 0
}

export const location = _svelteContext.location

// gettext
// FIXME: die locale map sollte automatisch erstellt werden und in locales/index.js stehen
import gettext from './gettext.js'
import locales from './locales/index.js'

const { locale, catalog } = locales[_svelteContext.language]
const i18n = gettext({ locale })
i18n.setMessages(
  'messages',
  locale,
  catalog['messages'],
  catalog['plural-forms']
)

function __() {
  return i18n.gettext(...arguments)
}
__.p = function () {
  return i18n.ngettext(...arguments)
}

export { __ }

// date-fns locales

import { de, enGB as en, nl, tr, es, ru, fr, it, pl, ro, lt, zhCN as zh, pt, ar, sr, hr, ka } from 'date-fns/locale'
import { format } from 'date-fns';

const datefnsLocales = { de, en, nl, tr, es, ru, fr, it, pl, ro, lt, zh, ga: en, pt, ar, sr, hr, ka, "de-ch": de }

export const datefns_locale = () => datefnsLocales[_svelteContext.language]

export const format_date = (d) => {
  return format(d, "Pp", { locale: datefns_locale() })
}


// Omnibus
export const open_channel = (topic) => window.get_websocket_monitor_channel(topic)

export const omnibus_subscribe = (topic, event, cb) => {
  const channel = open_channel(topic)
  channel.on(event, cb)
  return () => {
    channel.off()
    channel.close()
  }
}
