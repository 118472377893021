<script>
  import DeviceEvents from './toolbar/DeviceEvents.svelte'
  import EmergencyShutdown from './toolbar/EmergencyShutdown.svelte'
  import ServiceTickets from './toolbar/ServiceTickets.svelte'
  import ImportedInmates from './toolbar/ImportedInmates.svelte'
  import MobilewallEvents from './toolbar/MobilewallEvents.svelte'
  import CallPanelEvents from './toolbar/CallPanelEvents.svelte'
  import PendingURLs from './toolbar/PendingURLs.svelte'
  import PendingMails from './toolbar/PendingMails.svelte'
  import PendingPrintJobs from './toolbar/PendingPrintJobs.svelte'
  import InmateRequests from './toolbar/InmateRequests.svelte'
  import WaitingCalls from './toolbar/WaitingCalls.svelte'
  import OpenOrders from './toolbar/OpenOrders.svelte'
  import ContactRequests from './toolbar/ContactRequests.svelte'
  import { onMount, onDestroy } from 'svelte'
  import { Howl } from 'howler'
  import { check_feature } from './utils.js'

  let sessions = []
  let dingSound = new Howl({
    src: [
      '/static/ccbase/sfx/ding.mp3',
      '/static/ccbase/sfx/ding.ogg',
      '/static/ccbase/sfx/ding.wav',
    ],
    preload: true,
  })

  let websocket, timer, active

  function connectSock() {
    timer = null
    websocket = new WebSocket(
      window.location.protocol.replace('http', 'ws') +
        '//' +
        window.location.host +
        '/sessionUpdates'
    )

    websocket.onmessage = (event) => {
      // console.log('onmessage', event, websocket)

      data = event.data
      const session = sessions.find((ss) => ss == data.session)
      if (session && data.status == 'callReleased')
        sessions = sessions.filter((ss) => ss != data.session)
      else if (
        !session &&
        ['callAwaitApproval', 'callEstablished'].includes(data.status)
      )
        sessions = [...sessions, data.session]
      else return

      dingSound.stop()
      dingSound.play()
    }

    websocket.onclose = (event) => {
      // console.log('onclose', event, websocket)
      websocket = null
      if (active) timer = setTimeout(connectSock, 1000)
    }
  }

  onMount(() => {
    active = true
    if (check_feature('callsession_tone')) connectSock()
  })

  onDestroy(() => {
    active = false
    websocket?.close()
    clearTimeout(timer)
  })
</script>

<div>
  <WaitingCalls />
  <CallPanelEvents />
  <ServiceTickets />
  <ImportedInmates />
  <ContactRequests />
  <EmergencyShutdown />
  <InmateRequests />
  <PendingMails />
  <PendingURLs />
  <MobilewallEvents />
  <DeviceEvents />
  <PendingPrintJobs />
  <OpenOrders />
</div>
