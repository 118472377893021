<script context="module">
  export const CALENDAR_VIEWS = {
    Month: Symbol(),
    Week: Symbol(),
    Day: Symbol(),
  }
</script>

<script>
  /** @typedef { import("./types").CalendarEvent } CalendarEvent */
  import {
    addDays,
    differenceInDays,
    differenceInHours,
    differenceInMonths,
    isSameDay,
    isSameMonth,
    isToday,
    isWeekend,
    set as setDatefns,
    startOfDay,
    startOfMonth,
    startOfWeek,
  } from 'date-fns'
  import { createEventDispatcher } from 'svelte'
  import { __ } from '../utils.js'
  import { MONTHS, WEEK_DAYS } from './CalendarBase.svelte'

  const dispatch = createEventDispatcher()

  export let targetedDate = new Date()

  /** @type {CalendarEvent[]} */
  export let events = []

  /** @type {CalendarEvent} */
  let hoveredEvent

  const FIRST_WEEK_DAY = 1
  const NB_CELLS_IN_CALENDAR = 42
  const MAX_EVENTS_PER_DAY = 2

  $: __more = __('more')

  $: firstDayShown = startOfWeek(startOfMonth(targetedDate), {
    weekStartsOn: FIRST_WEEK_DAY,
  })
  $: lastDayShown = addDays(firstDayShown, NB_CELLS_IN_CALENDAR)

  $: weekDays = WEEK_DAYS.map((__, i) => WEEK_DAYS[(i + FIRST_WEEK_DAY) % 7])

  let cellDays = []

  $: moveToDate(targetedDate)

  const moveToDate = (targetedDate) => {
    const target = setDatefns(targetedDate, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })

    // console.warn(target, { m })
    if (
      cellDays?.length &&
      isSameMonth(cellDays[~~(cellDays.length / 2)]._fulldate, target)
    ) {
      // no need to recalculate the grid if we are still on the same month
      return
    }

    // init list of cells
    if (!cellDays?.length)
      cellDays = Array.from(Array(NB_CELLS_IN_CALENDAR), () => ({
        slots: Array(MAX_EVENTS_PER_DAY + 1),
      }))

    cellDays = cellDays.map((cell, ic) => {
      const thisDate = addDays(firstDayShown, ic)

      cell._fulldate = thisDate
      cell._weekEnd = isWeekend(thisDate)
      cell._today = isToday(thisDate)

      if (isSameMonth(thisDate, target)) {
        delete cell._prevMonth
        delete cell._nextMonth
      } else if (differenceInMonths(thisDate, target) > 0) {
        cell._nextMonth = true
      } else {
        cell._prevMonth = true
      }

      return cell
    })
  }

  $: _events = events
    .filter((event) => {
      return (
        differenceInDays(event.start, lastDayShown) <= 0 ||
        differenceInDays(event.end || event.start, firstDayShown) >= 0
      )
    })
    .sort((a, b) => a.start.getTime() - b.start.getTime())

  // $: console.log('Month', {events})

  $: applyEvents(_events)
  /** @param {CalendarEvent[]} _events */
  const applyEvents = (_events) => {
    // console.warn('Month: applyEvents', targetedDate, _events)

    // rude
    cellDays.forEach((c) => {
      c.slots = Array(MAX_EVENTS_PER_DAY + 1)
    })

    _events.forEach((event, __i) => {
      let start = new Date(event.start)
      let end = event.end ? new Date(event.end) : addDays(start, 1)

      if (event.allday) {
        start = startOfDay(start)
        end = startOfDay(addDays(end, 1))
      }

      const iFirstCellOfEvent = Math.max(
        0,
        differenceInDays(start, firstDayShown)
      )
      const iLastCellOfEvent = Math.min(
        cellDays.length - 1,
        differenceInDays(end, firstDayShown) + (event.allday ? 0 : 1)
      )
      // + (event.allday ? 1 : 0)

      // console[event.id == 16 ? 'group' : 'groupCollapsed'](
      //   Number(event.id).toLocaleString('en-US', {
      //     minimumIntegerDigits: 2,
      //   }),
      //   event.title
      // )

      // console.log(
      //   { iFirstCellOfEvent, iLastCellOfEvent },
      //   { end, firstDayShown },
      //   { diff: differenceInDays(end, firstDayShown) }
      // )

      // Find the highest available section by going over all cells covered by this event

      let level = -1
      for (let iCell = iFirstCellOfEvent; iCell <= iLastCellOfEvent; iCell++) {
        const openSlot = cellDays[iCell].slots.findIndex((o) => !o)

        if (openSlot >= MAX_EVENTS_PER_DAY) {
          level = -1
          const overflow = [...(cellDays[iCell].slots[openSlot] || []), event]
          cellDays[iCell].slots[openSlot] = {
            isMore: true,
            overflow,
            caption: `${overflow.length} ${__more}...`,
            title: overflow.map((ev) => `- ${ev.title}`).join('\n'),
          }
          break
        }
        level = Math.max(level, openSlot)
      }

      // console.groupEnd()

      if (level < 0) {
        return
      }

      let isFirstSection =
        differenceInDays(start, firstDayShown) < 0 ? null : true

      // Register the cell slots this event is taking
      for (let iCell = iFirstCellOfEvent; iCell < iLastCellOfEvent; iCell++) {
        const iEvent = iCell - iFirstCellOfEvent
        const isSection = iEvent == 0 || iCell % 7 == 0

        const length = Math.max(
          Math.min(
            Math.ceil(differenceInHours(end, cellDays[iCell]._fulldate) / 24),
            7 - (iCell % 7)
          ),
          1
        )

        if (isSection)
          cellDays[iCell].slots[level] = {
            event,
            length,
            isSection,
            isFirst: isFirstSection,
            isLast: isSection && iCell + length === iLastCellOfEvent,
          }
        else
          cellDays[iCell].slots[level] = {
            event,
          }

        if (isSection && isFirstSection === true) isFirstSection = false
      }
    })
  }
</script>

<style type="text/postcss">
  .monthview {
    user-select: none;
  }
  .gridDates {
    height: 100%;
    display: grid;
    grid-template-rows: 1.5em repeat(6, minmax(0, 1fr));
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .cellDay {
    overflow: visible;
    font-size: small;
    display: flex;
    flex-direction: column;
    padding: 0.3em 0;
    outline: 0.1em solid #eee6;
  }
  .cellDay > .title {
    align-self: center;
    flex: 0 1 1.5em;
    min-width: 1.8em;
    line-height: normal;
    border-radius: 1em;
  }
  .cellDay.weekend {
    background-color: #3331;
  }
  .cellDay.prev-month > .title,
  .cellDay.next-month > .title {
    color: #ccc;
  }
  .cellDay.today > .title {
    @apply bg-primary-900;
    padding: 0 0.2em;
    color: #fff;
    outline: 0.1em solid #fff2;
  }
  .cellDay.focused,
  .focused {
    @apply bg-primary-900;
  }
  .cellDay.focused .event-slot:empty::before {
    color: white;
  }
  .event-slot {
    overflow: hidden;
    flex: 0 1 1.75em;
    padding: 0 0.5em;
    background: transparent;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .event-slot:empty {
    background: none;
  }
  .event-slot.section {
    z-index: 1;
  }
  .event-slot.section.first {
    margin-left: var(--event-margin-x);
  }
  .event-slot.section.hover {
    z-index: 2;
  }
  .event-slot:not(.first) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .event-slot:not(.last) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
</style>

<div class="monthview flex flex-col h-full w-full overflow-hidden">
  <div class="gridDates">
    <!-- header -->
    {#each weekDays as day, iDay}
      <span
        class="day-header"
        class:focused="{iDay === (targetedDate.getDay() - FIRST_WEEK_DAY) % 7}"
        >{day.substr(0, 3)}</span>
    {/each}

    <!-- <span class="cellDay" style="grid-column-start: 3;">cell#0</span> -->
    {#each cellDays as cell, ic (ic)}
      <div
        class="cellDay"
        class:weekend="{cell._weekEnd}"
        class:today="{cell._today}"
        class:focused="{isSameDay(cell._fulldate, targetedDate)}"
        class:prev-month="{cell._prevMonth}"
        class:next-month="{cell._nextMonth}"
        on:click="{(e) => {
          moveToDate(cell._fulldate)
          dispatch('clickDay', { date: cell._fulldate })
        }}"
        on:dblclick|stopPropagation="{(e) => {
          dispatch('dblclickDay', { date: cell._fulldate })
        }}">
        <div class="title text-center">
          {cell._fulldate.getDate()}
          {cell._fulldate.getDate() === 1
            ? MONTHS[cell._fulldate.getMonth() % 12].substr(0, 3)
            : ''}
        </div>
        <div class="flex flex-col">
          <!-- <span
            style="color:#00fa;position:absolute;left:50%;bottom:50%;z-index:2;"
            >{ic}</span> -->
          {#if cell._text}
            <pre style="font-size:small;">{cell._text || ''}</pre>
          {/if}

          {#each cell.slots as section, ie (ie)}
            {#if section?.event && section.isSection}
              <button
                class="event-slot"
                class:section="{section.isSection}"
                class:first="{section.isFirst}"
                class:last="{section.isLast}"
                class:allday="{section.event.allday}"
                class:hover="{hoveredEvent === section.event}"
                data-length="{section.length}"
                data-allday="{section.allday}"
                data-isFirst="{section.isFirst}"
                data-isLast="{section.isLast}"
                style="{`width: calc(${section.length * 100}% - (
                  ${section.isLast ? 1 : 0} + ${section.isFirst ? 1 : 0}
                ) * var(--event-margin-x));`}"
                on:click|stopPropagation="{() => {
                  dispatch('clickEvent', { event: section.event })
                }}"
                on:mouseenter="{() => {
                  hoveredEvent = section.event
                }}"
                on:mouseleave="{() => {
                  hoveredEvent = null
                }}">
                <!-- {JSON.stringify(section)} -->
                <!-- {section.id}# {section.event.title} -->
                {section.event.title}
                <!-- {section.event.title.substr(0, 2)} {section.start.getDate()} - {section.end.getDate()} -->
              </button>
            {:else if section?.isMore}
              <button
                class="event-slot text-center font-bold select-none cursor-pointer-m-1"
                on:click|stopPropagation="{(e) => {
                  dispatch('dblclickDay', { date: cell._fulldate })
                }}"
                title="{section.title}">
                {section.caption}
              </button>
            {:else}
              <div class="event-slot"></div>
            {/if}
          {/each}

          <!-- <div
            class="absolute top-0 left-0"
            style="color:#f00e; font-size:.5em; z-index:999;">
            {ic}
          </div> -->
        </div>
      </div>
    {:else}
      loading... {JSON.stringify(cellDays)}
    {/each}
  </div>
</div>
