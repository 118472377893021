<script>
  export let color
  export let value = undefined
  export let label
  export let tooltip = undefined
  export let pulse = false
  export let warning = false
  export let url = undefined

  const make_color = (col, opacity) => {
    const rgb = parseInt(col.slice(1), 16)
    const r = (rgb & 0xff0000) >> 16
    const g = (rgb & 0x00ff00) >> 8
    const b = (rgb & 0x0000ff) >> 0
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }

  $: _col = warning ? color : '#ffffff'
  $: bg_color = make_color(_col, 0.3)

  const on_click = () => {
    if (url) window.location = url
  }
</script>

<style>
  @-moz-keyframes escalate_pulse {
    0% {
      opacity: 1;
    }
    30% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.2;
    }
    70% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  } /* Firefox */
  @-webkit-keyframes escalate_pulse {
    0% {
      opacity: 1;
    }
    30% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.2;
    }
    70% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  } /* Webkit */
  @-ms-keyframes escalate_pulse {
    0% {
      opacity: 1;
    }
    30% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.2;
    }
    70% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  } /* IE */
  @keyframes escalate_pulse {
    0% {
      opacity: 1;
    }
    30% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.2;
    }
    70% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  } /* Opera */

  .pulse {
    animation: escalate_pulse 1s infinite;
  }
</style>

<div
  on:click="{on_click}"
  on:click
  class="float-left block shadow-xl font-normal cursor-pointer mr-4"
  style="background-color: rgba(255,255,255,1.0);"
  title="{tooltip || label}">
  <div
    class="flex flex-row p-4 items-center"
    class:pulse
    style="background-color: {bg_color};">
    <div
      class="text-xl font-bold p-1 h-16 w-16 text-white rounded-full flex items-center justify-center"
      style="background-color: {color};">
      <slot />
    </div>
    <div class="flex flex-col ml-6">
      <div class="text-3xl" style="color: {color};">
        {#if value}{value}{:else}&nbsp;{/if}
      </div>
      <div class="text-gray-600 text-xl">{label}</div>
    </div>
  </div>
</div>
