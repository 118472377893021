import Shortcuts from './Shortcuts.svelte'
import AddressBook from './AddressBook.svelte'
import AlarmList from './AlarmList.svelte'
import Calendar from './Calendar.svelte'
import LeisureActivity from './LeisureActivity.svelte'
import BlogReceivers from './BlogReceivers.svelte'
import DatePicker from './DatePicker.svelte'
import DateInput from './DateInput.svelte'
import domReady from 'domready'
import './globals.css'
import { browser_id } from './utils.js'

const callbacks = {
  shortcuts: Shortcuts,
  datepicker: DatePicker,
  dateinput: DateInput,
  AddressBook,
  AlarmList,
  Calendar,
  LeisureActivity,
  BlogReceivers,
}

domReady(function() {
  window.svelteLoaded(callbacks)
})
