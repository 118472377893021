<script>
  import { get_current_component } from 'svelte/internal'

  export let el = null
  export let className = null
  export let value = false
  export let disabled = false
  export let checked = value

  /* Hack to simulate our change event. It is despicable to do such things.
   * It came down to this to keep the "Enter" key press to look like a change of "checked".
   * Normally, pressing enter does not toggle checkbox input. But we want it to work with
   * the "OK" button of the PMTV's remotes.
   * inpired by: https://stackoverflow.com/a/66985113
   */
  const fakeNativeDispatch = (() => {
    const self = get_current_component()
    return (type, detail) => {
      const callbacks = self.$$.callbacks[type]
      if (callbacks) {
        const event = new CustomEvent(type, detail)
        // the key is to call `dispatchEvent` manually to set `event.target`
        el.dispatchEvent(event)
        callbacks.slice().forEach((fn) => {
          fn.call(self, event)
        })
      }
    }
  })()
</script>

<style type="text/postcss">
  input:focus {
    outline-offset: 0;
    outline: 5px auto -webkit-focus-ring-color;
  }
  /* https://codepen.io/loktar00/pen/RwZpBab */
  input {
    aspect-ratio: 1;
    overflow: hidden;
    margin: 0;
    flex: 1 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    appearance: none;
    border-radius: 0.15em;
    /* box-shadow: 0 0 0 0.2em white; */
    background-color: white;
    border-width: thin;
    border-style: solid;
    border-color: initial;
  }

  input:checked {
    @apply bg-primary-900;
  }

  input:disabled {
    @apply bg-gray-400;
  }

  input::after {
    content: '✓';
    border-radius: inherit;
    font-weight: bold;
    opacity: 0;
  }
  input:checked::after {
    opacity: 1;
  }
</style>

<input
  class="{className}"
  type="checkbox"
  bind:checked
  bind:value
  bind:this="{el}"
  on:keyup="{(e) => {
    if (e.code == 'Enter') {
      el.checked = !el.checked
      fakeNativeDispatch('change', e)
    }
  }}"
  on:change
  disabled="{disabled}"
  {...$$restProps} />
