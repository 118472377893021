<script>
  import { onMount } from 'svelte'
  import { has_permission, check_feature, omnibus_subscribe, ajax, __ } from '../utils.js'
  import ButtonIcon from './ButtonIcon.svelte'
  import { mdiInbox } from '@mdi/js'

  let value = null

  const reload_status = () => {
    ajax.get('/ajax/inmate_requests').then((e) => {
      value = e.data?.result
    })
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe('prisec', 'newinmaterequests_counter', reload_status)
  })
</script>

{#if check_feature('prisonmedia_requests') && has_permission('inmate_prisonmedia_requests_view') && value}
  <ButtonIcon
    value="{value}"
    warning="{value > 10}"
    color="#77B2AE"
    icon="{mdiInbox}"
    tooltip="{__('View new inmate requests')}"
    label="{__('Requests')}"
    url="/requests/InmateRequests?panelX=panel_0&status=03c2e7e41ffc181a4e84080b4710e81e" />
{/if}
