<script>
  import { __ } from './utils.js'

  export let value

  export let _this = null

  const instanceId = Math.random()

  $: dayCaptions = [
    __('monday'),
    __('tuesday'),
    __('wednesday'),
    __('thursday'),
    __('friday'),
    __('saturday'),
    __('sunday'),
  ]

  let els = []

  const onChange = (day, target) => {
    if (!value && target.checked) value = []

    value[day] = target.checked
  }

  const focusPreviousInput = () => {
    const activeIndex = els.findIndex((el) => el == document.activeElement)
    els[(activeIndex > 0 ? activeIndex : els.length) - 1].focus()
  }

  const focusNextInput = () => {
    const activeIndex = els.findIndex((el) => el == document.activeElement)
    els[(activeIndex + 1) % els?.length].focus()
  }

  const onKeydown = (e) => {
    // TODO: debounce

    switch (e.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        focusPreviousInput()
        break

      case 'ArrowDown':
      case 'ArrowRight':
        focusNextInput()
        break

      case 'Enter':
        // toggleFocusedInput
        // PMTV doesn't toggle it for some reason 🤷
        const activeIndex = els.findIndex((el) => el == document.activeElement)
        value[activeIndex] = !value[activeIndex]
        break

      default:
        break
    }
  }
</script>

<style>
  input:focus + label {
    /* Copy from Bootstrap input[type="checkbox"]:focus} */
    outline: 5px auto -webkit-focus-ring-color;
  }
</style>

<div
  tabindex="-1"
  class="{`m-1 p-px h-8 items-stretch flex justify-around overflow-hidden rounded bg-white ${
    $$props.className || ''
  }`}"
  on:keydown="{onKeydown}"
  bind:this="{_this}"
  {...$$restProps}>
  {#each dayCaptions as day, i}
    <div class="m-px relative w-full flex-1" style="max-width: 1em;">
      <input
        bind:this="{els[i]}"
        type="checkbox"
        id="{`${instanceId}_${i}`}"
        checked="{value?.[i] === true}"
        class="absolute top-0 w-0 h-0 m-0 p-0 opacity-0"
        on:change="{({ target }) => onChange(i, target)}" />
      <label
        for="{`${instanceId}_${i}`}"
        class="m-0 h-full font-bold text-base rounded-3xl flex justify-center items-center"
        class:text-white="{value?.[i] === true}"
        class:bg-primary-900="{value?.[i] === true}">
        {day[0].toUpperCase()}
      </label>
    </div>
  {/each}
</div>
