<script>
  import {
    mdiCheckBold,
    mdiPencil,
    mdiPlusThick,
    mdiTrashCanOutline,
    mdiViewList,
  } from '@mdi/js'
  import Icon from 'mdi-svelte'
  import { createEventDispatcher, onMount, tick } from 'svelte'
  import DaySelector from './DaySelector.svelte'
  import Checkbox from './uielements/Checkbox.svelte'
  import Spinner from './uielements/Spinner.svelte'
  import { ajax } from './utils.js'

  const dispatch = createEventDispatcher()

  export let title
  export let titleCreate
  export let titleEdit

  // a action that can be carried when pressing the blue button, or space
  export let actionBlueSpace = null

  export let baseURL

  export let maxItems = Infinity

  export let CAN_CREATE = true
  export let CAN_EDIT = true
  export let CAN_DELETE = true

  /**
   * @type {{ name: String, caption: String, props: {type: String} }[]}
   */
  export let fields

  export let sortField
  $: _sortField = sortField || fields[0].name

  export let transformIncomingItem = (itm) => itm
  export let transformOutgoingItem = (itm) => itm

  export let getDefaultItem = () => ({})

  let isPending

  const _getDefaultItem = () => {
    return {
      payload: {},
      isNew: true,
      // id: Math.round(Math.random() * Math.pow(10, 16)).toString(),
      ...getDefaultItem(),
    }
  }

  let items = []

  const setItems = async (input) => {
    if (!input?.length) {
      // input = [_getDefaultItem()]
    }

    if (maxItems) {
      if (input.length > maxItems) {
        console.warn('input list will be sliced to "maxItems"', {
          input,
          maxItems,
        })
      }

      input = input.slice(0, maxItems)
    }

    items = sortItems(input)

    if (!lastFocusedId && items?.length) lastFocusedId = items[0].id
  }

  /**
   * @type {{ id: String, payload: {name: String} }}
   */
  let editItem = false

  let lastFocusedId

  $: focusedItem = getFocusedItem(lastFocusedId)

  const listItems = async () => {
    const { data, status } = await ajax.get(baseURL, {
      params: { format: 'json' },
    })

    setItems(data.objects?.map(transformIncomingItem))
  }

  onMount(listItems)

  const onSave = async (item = editItem) => {
    if (!item) {
      return console.error(item, editItem, items)
    }
    isPending = true
    editItem = null

    const { id, isNew } = item

    // data to send
    const updItem = transformOutgoingItem({
      id,
      payload: {
        ...(items.find((c) => c.id == id) || {}).payload,
        ...item.payload,
      },
    })

    try {
      if (isNew) {
        const { data, status } = await ajax.post(baseURL, updItem)

        item = transformIncomingItem({
          ...item,
          id: data.id,
          payload: data.payload,
        })

        setItems([...items, item])
      } else {
        const { data, status } = await ajax.put(`${baseURL}${id}/`, updItem)

        item = transformIncomingItem(data)
        setItems(items.map((ii) => (ii.id === item.id ? item : ii)))
      }

      lastFocusedId = item.id
      dispatch('save', item)
    } catch (ex) {
      return console.error(ex?.response?.data?.error_message ?? ex)
    } finally {
      isPending = false
    }

    await tick()
  }

  const onDelete = async () => {
    const item = editItem || getFocusedItem()
    if (!item) return console.error(item, editItem, items)

    isPending = true
    editItem = null
    lastFocusedId = null

    if (item.isNew)
      // this item was never sent to the backend
      return

    try {
      const { data, status } = await ajax.delete(`${baseURL}${item.id}/`)

      focusNextItem()

      items = [...items.filter((crt) => crt.id !== item.id)]

      dispatch('delete', editItem)

      editItem = false
    } catch (ex) {
      return console.error(ex?.response?.data?.error_message ?? ex)
    } finally {
      isPending = false
    }
  }

  const onNew = () => (editItem = _getDefaultItem())

  const onEdit = (c = items.find((itm) => itm.id == lastFocusedId)) => {
    editItem = { ...c }
  }

  const onClose = () => (editItem = false)

  function sortItems(items) {
    return items?.sort((a, b) => {
      const A = a.payload?.[_sortField]?.toLowerCase()
      const B = b.payload?.[_sortField]?.toLowerCase()
      return A > B ? 1 : A == B ? 0 : -1
    })
  }

  const getFocusedItem = function () {
    const itm = lastFocusedId && items.find(({ id }) => lastFocusedId == id)
    return itm
  }

  const focusNextItem = function () {
    if (!items?.length) return

    const itemN = lastFocusedId
      ? items.findIndex(({ id }) => lastFocusedId == id)
      : 0

    const itemId = items[(itemN + 1) % items?.length].id

    document.getElementById(`cruditem-${itemId}`).focus()
  }

  const focusPreviousItem = function () {
    if (!items?.length) return

    const itemN = lastFocusedId
      ? items.findIndex(({ id }) => lastFocusedId == id)
      : items.length

    const itemId = items[(itemN > 0 ? itemN : items.length) - 1].id

    document.getElementById(`cruditem-${itemId}`).focus()
  }

  const getFocusedDlgIndex = () => {
    let activeIndex = dlgInputs.findIndex((el) => el == document.activeElement)
    if (activeIndex === -1) {
      // search deeper
      activeIndex = dlgInputs.findIndex((el) => {
        return el?.contains(document.activeElement)
      })
    }

    return Number.isInteger(activeIndex) && activeIndex >= 0 ? activeIndex : 0
  }

  const focusPreviousDlgInput = function () {
    const idx = getFocusedDlgIndex()
    dlgInputs[(idx > 0 ? idx : dlgInputs.length) - 1]?.focus()
  }

  const focusNextDlgInput = function () {
    const idx = getFocusedDlgIndex()
    dlgInputs[(idx + 1) % dlgInputs.length]?.focus()
  }

  const onKeydown = (e) => {
    // TODO: debounce
    if (editItem) {
      // Edit dialog
      switch (e.key) {
        case 'ColorF0Red':
          return CAN_DELETE && onDelete()

        case 'ColorF1Green':
        case 'Escape':
        case 'Cancel':
        case 'GoBack':
          return (editItem = false)

        case 'ColorF2Yellow':
          return CAN_EDIT || CAN_CREATE ? onSave() : (editItem = false)

        case 'ChannelDown':
        case 'PageDown':
          focusNextDlgInput()
          break

        case 'ChannelUp':
        case 'PageUp':
          focusPreviousDlgInput()
          break

        default:
        // disabled because it would prevents to enter '1' in text input
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          break
      }
    } else {
      switch (e.key) {
        case '1':
        case 'ColorF0Red':
          return CAN_DELETE && onDelete()
        case '2':
        case 'ColorF1Green':
        case 'Enter':
          return CAN_EDIT && onEdit()
        case '3':
        case 'ColorF2Yellow':
          return CAN_CREATE && onNew()
        case '4':
        case ' ' /* space */:
        case 'ColorF3Blue':
          return actionBlueSpace
            ? actionBlueSpace({ ...focusedItem }, onSave)
            : CAN_EDIT && onEdit()
        case '5':
        case 'ChannelDown':
        case 'PageDown':
        case 'ArrowDown':
        case 'ArrowRight':
          return focusNextItem()
        case '6':
        case 'ChannelUp':
        case 'PageUp':
        case 'ArrowUp':
        case 'ArrowLeft':
          return focusPreviousItem()
        default:
          break
      }
    }
  }

  const dlgInputs = []

  function dialogAction(node) {
    // onMount

    // focus on the first input we can find
    const firstInput = node.querySelector('li input')

    if (firstInput) {
      firstInput.focus()
      firstInput.select()
    }

    return {
      destroy: () => {
        const id = getFocusedItem()?.id
        if (!id) return

        const el = document.getElementById(`cruditem-${id}`)
        setTimeout(() => {
          el?.scrollIntoView?.()
          el?.focus?.()
        })
      },
    }
  }
</script>

<style type="text/postcss">
  li:focus,
  input:focus {
    outline: none;
  }

  .dlginput:focus-within {
    @apply bg-primary-900;
  }
</style>

<svelte:window on:keydown="{onKeydown}" />

<!-- svelte-ignore a11y-autofocus -->
<div
  class="relative h-full overflow-hidden flex flex-col mx-10 rounded-xl bg-gray-400 border-solid border-4 border-gray-100"
  class:border-primary-100="{!editItem}">
  {#if isPending}
    <div class="absolute top-0 w-full m-1">
      <Spinner />
    </div>
  {/if}
  {#if title}
    <div
      class="p-4 text-2xl font-medium border-0 border-solid border-b border-gray-500">
      {title}
    </div>
  {/if}

  <ul
    id="item-list"
    class="flex-1 overflow-auto flex flex-col m-0 p-0 bg-white">
    {#if items}
      {#each items as crt, j (crt.id)}
        <li
          id="cruditem-{crt.id}"
          class="{`border-0 border-b border-solid border-gray-200 select-none ${
            lastFocusedId === crt.id ? 'bg-primary-900' : 'bg-transparent'
          }`}"
          tabindex="{/* Make it unfocusable when the dialog is opened */
          editItem ? -1 : 0}"
          autofocus="{!j}"
          on:focus="{(e) => (lastFocusedId = crt.id)}"
          on:click="{(e) => {
            if (e.target.nodeName === 'INPUT') return
            if (CAN_EDIT) onEdit(crt)
          }}">
          <slot name="list-item" item="{crt}" onSave="{onSave}">
            <div class="flex p-2">
              <div class="p-1 text-muted">
                {crt.payload[fields[0].name] ?? ''}
              </div>
              <div class="p-1 px-4 flex-1 text-left">
                {crt.payload[fields[1].name] ?? ''}
              </div>
              <div class="p-1 text-muted">
                {crt.payload[fields[2].name] ?? ''}
              </div>
            </div>
          </slot>
        </li>
      {/each}
    {/if}
  </ul>

  {#if editItem}
    <div
      class="absolute h-full w-full top-0 left-0 opacity-75"
      style="background-color:#DDD">
    </div>

    <dialog
      use:dialogAction
      open="{editItem}"
      class="w-3/4 h-full max-w-lg my-6 mx-auto p-0 flex flex-col overflow-hidden rounded-xl border-solid border-4 border-primary-100 shadow-xl"
      style="max-height: 80%; background-color:#DDD">
      <div
        class="p-4 text-2xl font-medium border-0 border-solid border-b border-gray-500">
        {editItem.id ? titleEdit : titleCreate}
      </div>
      <ul class="flex-1 p-0 bg-white overflow-y-scroll">
        {#each fields as fld, fldI (fld.name)}
          <li
            class="dlginput p-2 border-0 border-b border-solid border-gray-200"
            tabindex="-1">
            {#if fld.props?.type == 'day'}
              <div class="text-base p-1">
                {fld.caption}
              </div>
              <DaySelector
                bind:_this="{dlgInputs[fldI]}"
                {...fld.props}
                value="{editItem.payload[fld.name] || ''}"
                on:input="{({ target }) =>
                  (editItem.payload[fld.name] = target.value)}" />
            {:else if fld.props?.type == 'select' && fld.options?.length}
              <div class="text-base p-1">
                {fld.caption}
              </div>
              <select
                bind:this="{dlgInputs[fldI]}"
                {...fld.props}
                class="w-full"
                placeholder="..."
                value="{editItem.payload[fld.name]}"
                on:input="{({ target }) => {
                  editItem.payload[fld.name] = fld.options.findIndex(
                    (opt) => opt === target.value
                  )
                }}">
                {#each fld.options as opt, i}
                  <option
                    value="{i}"
                    selected="{fld.options.some(
                      (opt) => opt === editItem.payload[fld.name]
                    )}">
                    {opt}
                  </option>
                {/each}
              </select>
            {:else if fld.props?.type == 'checkbox'}
              <div class="w-full">
                <label for="{`fld_${editItem.id}_${fldI}`}" class="w-full flex">
                  <div>
                    <Checkbox
                      className="m-0"
                      bind:el="{dlgInputs[fldI]}"
                      value="{editItem.payload[fld.name]}"
                      {...fld.props}
                      id="{`fld_${editItem.id}_${fldI}`}"
                      on:change="{({ target }) =>
                        (editItem.payload[fld.name] = target.checked)}" />
                  </div>
                  <div class="p-1 pl-2 text-base font-normal">
                    {fld.caption}
                  </div>
                </label>
              </div>
            {:else}
              <div class="text-base p-1">
                {fld.caption}
              </div>
              <input
                bind:this="{dlgInputs[fldI]}"
                class="w-full"
                type="text"
                {...fld.props}
                placeholder="{fld.caption}"
                value="{editItem.payload[fld.name] || ''}"
                checked="{editItem.payload[fld.name] || ''}"
                on:input="{({ target }) =>
                  (editItem.payload[fld.name] = target.value)}" />
            {/if}
          </li>
        {/each}
      </ul>
    </dialog>
  {/if}
</div>
<!-- TODO clean up button visibility logic -->
<div
  class="controls absolute bottom-0 text-base flex m-1 rounded bg-black text-gray-900 select-none"
  style="left:50%; transform:translateX(-50%);">
  {#if CAN_DELETE && (focusedItem || editItem)}
    <button
      class="border-none bg-transparent m-2 rounded h-8 w-10"
      style="background-color: red"
      on:click="{onDelete}">
      <Icon path="{mdiTrashCanOutline}" size="1.5em" />
    </button>
  {/if}

  {#if CAN_EDIT}
    {#if editItem}
      <button
        class="border-none bg-transparent m-2 rounded h-8 w-10"
        style="background-color: green"
        on:click="{onClose}">
        <Icon path="{mdiViewList}" size="1.5em" />
      </button>
      <button
        class="border-none bg-transparent m-2 rounded h-8 w-10"
        style="background-color: yellow"
        on:click="{() => onSave(editItem)}">
        <Icon path="{mdiCheckBold}" size="1.5em" />
      </button>
      <div
        class="inline-flex flex-col justify-evenly items-stretch m-2 rounded bg-gray-500 h-8 w-10 leading-none font-medium text-xs">
        <div class="pl-1 pb-px border-0 border-b border-solid">CH +</div>
        <div class="pl-1">CH -</div>
      </div>
    {:else if focusedItem}
      <button
        class="border-none bg-transparent m-2 rounded h-8 w-10"
        style="background-color: green"
        on:click="{() => onEdit()}">
        <Icon path="{mdiPencil}" size="1.5em" />
      </button>
    {/if}
  {/if}

  {#if CAN_CREATE && !editItem}
    {#if items?.length < maxItems}
      <button
        class="border-none bg-transparent m-2 rounded h-8 w-10"
        style="background-color: yellow"
        on:click="{onNew}">
        <Icon path="{mdiPlusThick}" size="1.5em" />
      </button>
    {/if}
    {#if actionBlueSpace && focusedItem}
      <button
        class="border-none bg-transparent m-2 rounded h-8 w-10"
        style="background-color: blue"
        on:click="{() => {
          actionBlueSpace({ ...focusedItem }, onSave)
        }}">
        <Icon path="{mdiCheckBold}" size="1.5em" />
      </button>
    {/if}
  {/if}
</div>
