<script>
  /** @typedef { import("./calendar/types").CalendarEvent } CalendarEvent */

  import {
    addDays,
    addMonths,
    addWeeks,
    endOfMonth,
    endOfWeek,
    parseISO,
    startOfDay,
    startOfMonth,
    startOfWeek,
  } from 'date-fns'
  import { onMount } from 'svelte'
  import CalendarBase, { CALENDAR_VIEWS } from './calendar/CalendarBase.svelte'
  import EventDialog from './calendar/EventDialog.svelte'
  import Spinner from './uielements/Spinner.svelte'
  import { ajax, __ } from './utils.js'
  import { QWebChannel } from './qwebchannel.js'

  const baseURL = '/api/v1/event/'

  const CAN_CREATE_EVENT = true
  const CAN_EDIT_EVENT = true

  const noop = function () {}

  $: title = __('Calendar')

  let targetedDate = new Date()

  /** @type {CalendarEvent} */
  let openedEvent

  /** @type {CalendarEvent[]} */
  let events = []

  let loadingView
  let isPending = 0

  let activeView = CALENDAR_VIEWS.Month

  let QWCOperations = { save: null, remove: null }

  const transformIn = (evt) => ({
    id: evt.id,
    title: evt.payload.title,
    allday: evt.payload.allday,
    start: parseISO(evt.start + 'Z'),
    end: evt.end ? parseISO(evt.end + 'Z') : null,
  })

  const transformOut = (evt) => ({
    id: evt.id,
    payload: { title: evt.title, allday: evt.allday },
    start: evt.start.toISOString(),
    end: evt.end ? evt.end.toISOString() : null,
  })

  $: listItems(targetedDate, activeView)

  const listItems = async (targetedDate, activeView) => {
    if (!targetedDate) return

    let queryFilters = buildQueryFilters(targetedDate, activeView)

    if (
      loadingView &&
      loadingView.activeView == activeView &&
      loadingView.targetedDate == targetedDate.getTime()
    ) {
      // console.warn('Certainly some reactivity optimization todo here...')
      return
    }

    loadingView = { targetedDate: targetedDate.getTime(), activeView }

    try {
      const { data, status } = await ajax.get(baseURL, {
        params: { format: 'json', ...queryFilters },
      })
      const incoming = data.objects?.map(transformIn)
      // Keep some events cached...
      let oldies = events.filter(
        (old) => !incoming.find((i) => i.id === old.id)
      )
      // ...but not too many.
      if (oldies.length > 99) oldies.length = 99

      events = [...oldies, ...incoming]

      // console.log('listItems', activeView.name, targetedDate)
    } catch (err) {
      console.error(err?.response?.data?.error || err)
    } finally {
      loadingView = null
    }
  }

  function buildQueryFilters(targetedDate, activeView) {
    switch (activeView) {
      case CALENDAR_VIEWS.Day:
        return {
          start__lt: startOfDay(addDays(targetedDate, 1)),
          end__gte: startOfDay(targetedDate),
        }

      case CALENDAR_VIEWS.Week:
        return {
          start__lte: endOfWeek(targetedDate),
          end__gte: startOfWeek(targetedDate),
        }

      default:
      case CALENDAR_VIEWS.Month:
        return {
          // note: month view can show up to ~14 more days than the full month depending on its first date
          start__lte: addDays(endOfMonth(targetedDate), 14),
          end__gte: addDays(startOfMonth(targetedDate), -14),
        }
    }
  }

  onMount(listItems)

  const onSaveEvent = async (event) => {
    isPending++
    let updatedEntry = false

    events.map((e) => {
      if (e.id === event.id) {
        updatedEntry = e
        return transformOut(event)
      } else {
        return e
      }
    })
    events = updatedEntry ? events : [...events, event]

    openedEvent = null

    setTimeout(async () => {
      try {
        const { data, status } = await ajax.post(
          baseURL,
          transformOut(event),
          {}
        )

        // update backend
        QWCOperations.save?.(data)

        // re-update. in case backend modified our input
        events = events.map((e) => (e.id === event.id ? transformIn(data) : e))
      } catch (err) {
        console.error('⨯ onSaveEvent', err?.response?.data?.error || err)

        // restore the event to its pre-update state
        events = events.map((e) => (e.id === event.id ? updatedEntry : e))
      } finally {
        isPending--
      }
    })
  }

  const onDeleteEvent = async (event) => {
    isPending++
    events = events.filter((e) => e.id !== event.id)
    openedEvent = null

    setTimeout(async () => {
      try {
        const { data, status } = await ajax.delete(`${baseURL}${event.id}/`)
        QWCOperations.remove?.(event)
        console.warn('✓ onDeleteEvent', data)
      } catch (err) {
        console.error('⨯ onDeleteEvent', err?.response?.data?.error || err)
        events = [...events, event]
      } finally {
        isPending--
      }
    })
  }

  onMount(() => {
    if (!window.qt?.webChannelTransport) return

    new QWebChannel(qt.webChannelTransport, function (channel) {
      const errorHandler = (err) => console.error(err)

      QWCOperations.save = function (obj) {
        channel.objects.handler.save(obj, errorHandler)
      }
      QWCOperations.remove = function (obj) {
        channel.objects.handler.remove(obj.id, errorHandler)
      }
    })
  })
</script>

<style type="text/postcss">
  :global(button) {
    @apply rounded-lg border-2 border-gray-700 px-2;
  }
  :global(button:active) {
    @apply bg-primary-100;
  }

  :global(li) {
    list-style: none;
  }
</style>

<div class="relative h-full overflow-hidden">
  <div
    class="relative h-full overflow-hidden flex flex-col mx-10 rounded-xl border-solid border-4 border-primary-100">
    <div
      class="p-4 text-2xl font-medium bg-gray-400 border-0 border-solid border-b border-gray-500">
      <div>
        {#if loadingView || isPending}
          <div class="absolute top-0 w-full m-1">
            <Spinner />
          </div>
        {/if}
        <span>{title || ''}</span>
        <span style="float:right;">{targetedDate?.toDateString()}</span>
      </div>
      <div>
        <span class="view-selector">
          <button
            class:bg-primary-900="{activeView === CALENDAR_VIEWS.Month}"
            on:click="{() => {
              activeView = CALENDAR_VIEWS.Month
            }}">{CALENDAR_VIEWS.Month.name}</button>
          <button
            class:bg-primary-900="{activeView === CALENDAR_VIEWS.Week}"
            on:click="{() => {
              activeView = CALENDAR_VIEWS.Week
            }}">{CALENDAR_VIEWS.Week.name}</button>
          <button
            class:bg-primary-900="{activeView === CALENDAR_VIEWS.Day}"
            on:click="{() => {
              activeView = CALENDAR_VIEWS.Day
            }}">{CALENDAR_VIEWS.Day.name}</button>
        </span>
        <span style="float:right;">
          <button
            on:click="{() => {
              if (activeView === CALENDAR_VIEWS.Month) {
                targetedDate = addMonths(targetedDate, -1)
              } else if (activeView === CALENDAR_VIEWS.Week) {
                targetedDate = addWeeks(targetedDate, -1)
              } else if (activeView === CALENDAR_VIEWS.Day) {
                targetedDate = addDays(targetedDate, -1)
              }
            }}">&lt;</button>
          <button
            on:click="{() => {
              if (activeView === CALENDAR_VIEWS.Month) {
                targetedDate = addMonths(targetedDate, 1)
              } else if (activeView === CALENDAR_VIEWS.Week) {
                targetedDate = addWeeks(targetedDate, 1)
              } else if (activeView === CALENDAR_VIEWS.Day) {
                targetedDate = addDays(targetedDate, 1)
              }
            }}">&gt;</button>
        </span>
      </div>
    </div>

    <CalendarBase
      bind:targetedDate
      events="{events}"
      activeView="{activeView}"
      on:clickDay="{({ detail: { date } } = {}) => {
        targetedDate = date
        if (CAN_CREATE_EVENT) openedEvent = { start: date }
      }}"
      on:dblclickDay="{({ detail: { date } } = {}) => {
        targetedDate = date
        activeView = CALENDAR_VIEWS.Day
      }}"
      on:clickEvent="{CAN_EDIT_EVENT
        ? ({ detail: { event } } = {}) => {
            openedEvent = { ...event }
          }
        : noop}" />

    {#if openedEvent}
      <EventDialog
        event="{openedEvent}"
        on:close="{({ detail: event }) => {
          openedEvent = null
        }}"
        on:save="{({ detail: event }) => onSaveEvent(event)}"
        on:delete="{({ detail: event }) => onDeleteEvent(event)}" />
    {/if}
  </div>
</div>
