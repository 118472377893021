<script>
  import { onMount } from 'svelte'
  import { has_permission, check_feature, omnibus_subscribe, ajax, __ } from '../utils.js'
  import ButtonIcon from './ButtonIcon.svelte'
  import { mdiBandage } from '@mdi/js'

  let value = null

  const reload_status = () => {
    ajax.get('/ajax/service_tickets').then((e) => {
      value = e.data?.result
    })
  }

  onMount(() => {
    reload_status()
    return omnibus_subscribe('prisec', 'servicetickets_counter', reload_status)
  })
</script>

{#if check_feature('servicetickets') && has_permission('location_servicetickets_view') && value}
  <ButtonIcon
    value="{value}"
    warning="{value > 10}"
    color="#37AA4D"
    icon="{mdiBandage}"
    tooltip="{__('Unread service tickets')}"
    label="{__('Tickets')}"
    url="/Location/ServiceTickets" />
{/if}
