import lcl_dech from './de-CH/LC_MESSAGES/messages.po'
import lcl_de from './de/LC_MESSAGES/messages.po'
import lcl_en from './en/LC_MESSAGES/messages.po'
import lcl_fr from './fr/LC_MESSAGES/messages.po'
import lcl_pl from './pl/LC_MESSAGES/messages.po'

// ...

export default {
  de: { locale: 'de', catalog: lcl_de },
  en: { locale: 'en', catalog: lcl_en },
  pl: { locale: 'pl', catalog: lcl_pl },
  fr: { locale: 'fr', catalog: lcl_fr },
  'de-ch': { locale: 'de-CH', catalog: lcl_dech },
  // ...
}
